import { getConversationReference } from '@/lib/functions/firebase.helpers';
import { useEffect, useState } from 'react';
import useFirebase from './useFirebase';

const cache = {};
export default function useConversationObserver({
  conversationId,
  enabled,
  callback
}) {
  const [data, setData] = useState({});
  const { createRealtimeDatabaseObserver } = useFirebase();

  useEffect(() => {
    const observers = [];

    if (
      typeof enabled === 'undefined' ||
      (typeof enabled === 'boolean' && enabled)
    ) {
      //already initiated one time?
      if (!cache?.[conversationId]) {
        cache[conversationId] = true;
        observers.push({
          id: conversationId,
          destroy: createRealtimeDatabaseObserver(
            getConversationReference(conversationId),
            (snap) => {
              const value = snap.val();
              if (typeof callback === 'function') {
                callback(value, conversationId);
              }
              setData((prevData) => {
                return {
                  ...prevData,
                  [conversationId]: value
                };
              });
            }
          )
        });
      }
    }
    return () => {
      observers?.forEach((observer) => {
        if (observer?.id) {
          cache[observer?.id] = false;
        }
        if (typeof observer?.destroy === 'function') {
          observer?.destroy();
        }
      });
    };
  }, [conversationId, enabled]);

  return { data };
}

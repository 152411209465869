/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */

import '../styles/global.scss';
import { Provider } from 'react-redux';

import ThemeCustomization from '../themes';
import CssBaseline from '@mui/material/CssBaseline';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import store from '@/reduxtoolkit/store';
import { SnackbarProvider } from 'notistack';
import NextProgress from 'next-progress';
import FirebaseProvider from 'contexts/FirebaseProvider';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import App from 'next/app';
import { fetch_SocialLinks } from '@/api/Functions/cms.api';
import { set_footerLinkData } from '@/reduxtoolkit/globalSlice';

const queryClient = new QueryClient();

// eslint-disable-next-line react/prop-types
function MyApp({ Component, pageProps,footerData }) {


  if(!!footerData){
    store.dispatch(set_footerLinkData(footerData?.data?.data))
  }


  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps?.dehydratedState}>
          <FirebaseProvider>
            <NextProgress
              delay={300}
              options={{ showSpinner: false }}
              color="#ec031b"
              height="5px"
            />
            <SnackbarProvider
              maxSnack={3}
              autoHideDuration={2000}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <ThemeCustomization>
                <CssBaseline />
                <Component {...pageProps} />
              </ThemeCustomization>
            </SnackbarProvider>
          </FirebaseProvider>
        </Hydrate>
      </QueryClientProvider>
    </Provider>
  );
}

MyApp.getInitialProps = async (context) => {

  const appProps = await App.getInitialProps(context);

  const footerData=await fetch_SocialLinks();






  return { ...appProps,footerData };
};





export default MyApp;

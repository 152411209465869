/* eslint-disable import/no-mutable-exports */
import { initializeApp, getApps } from 'firebase/app';

import {
  CACHE_SIZE_UNLIMITED,
  initializeFirestore,
  enableMultiTabIndexedDbPersistence
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';
import { getPerformance } from 'firebase/performance';
import { getAnalytics } from 'firebase/analytics';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { checkCookie, checkWindow } from './_storage.lib';
const firebaseConfig = process.env.NEXT_APP_FIREBASE_CONFIG || {};
let firebaseApp = getApps()?.[0];
if (!firebaseApp) {
  firebaseApp = initializeApp(firebaseConfig);
}
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);
export const database = getDatabase(firebaseApp);
let performance = null;
let analytics = null;
if (checkWindow()) {
  performance = getPerformance(firebaseApp);
  analytics = getAnalytics(firebaseApp);
}

export { firebaseApp, performance, analytics };
if (checkWindow()) {
  //development stage add debug token
  if (window.location.host.includes('localhost')) {
    window.self.FIREBASE_APPCHECK_DEBUG_TOKEN =
      process.env.NEXT_APP_CHECK_DEBUG_TOKEN;
  }
  //for production need site recaptcha key
  initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider(process.env.NEXT_APP_APP_CHECK_SITE_KEY),
    isTokenAutoRefreshEnabled: true
  });
}
export const firestore = checkCookie()
  ? initializeFirestore(firebaseApp, {
      cacheSizeBytes: CACHE_SIZE_UNLIMITED
    })
  : {};

if (checkCookie()) {
  enableMultiTabIndexedDbPersistence(firestore); //multi index firestore enabled.
}

import { getUserDatabaseReference } from '@/lib/functions/firebase.helpers';
import { useEffect, useState } from 'react';
import useFirebase from './useFirebase';

const cache = {};
export default function useUserObserver({ userIds, enabled, callback }) {
  const [data, setData] = useState({});
  const { createRealtimeDatabaseObserver } = useFirebase();

  useEffect(() => {
    const observers = [];
    if (!Array.isArray(userIds)) {
      userIds = [userIds];
    }
    if (
      typeof enabled === 'undefined' ||
      (typeof enabled === 'boolean' && enabled)
    ) {
      userIds?.forEach((userId) => {
        //already initiated one time?
        if (!cache?.[userId]) {
          cache[userId] = true;
          observers.push({
            id: userId,
            destroy: createRealtimeDatabaseObserver(
              getUserDatabaseReference(userId),
              (snap) => {
                const value = snap.val();
                if (typeof callback === 'function') {
                  callback(value);
                }
                if (value?.id) {
                  setData((prevData) => {
                    return {
                      ...prevData,
                      [value.id]: value
                    };
                  });
                }
              }
            )
          });
        }
      });
    }
    return () => {
      observers?.forEach((observer) => {
        if (observer?.id) {
          cache[observer?.id] = false;
        }
        if (typeof observer?.destroy === 'function') {
          observer?.destroy();
        }
      });
    };
  }, [userIds, enabled]);

  return { data };
}

import { UserStatusEnums } from '@/hooks/useFirebaseProvider';
import ProfilePic, { ProfilePicUserDataProps } from '@/ui/Profile/ProfilePic';
import PropTypes from 'prop-types';
const { styled, Box } = require('@mui/material');

const MultipleGroupImageContainer = styled(Box)`
  position: relative;
  &.multi-image {
    height: 3rem;
    width: 3rem;

    .MuiBadge-root:nth-child(1) {
      position: absolute;
      top: 0;
      left: 0;
    }

    .MuiBadge-root:nth-child(2) {
      z-index: 2;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
`;

export default function ConversationImage({ images, size }) {
  
  return (
    <MultipleGroupImageContainer
      className={`${images?.length > 1 ? 'multi-image' : ''}`}
    >
      {images?.map((instance) => {
        return (
          <ProfilePic
            key={instance?.user?._id}
            isOnline={instance?.observer?.status === UserStatusEnums.online.key}
            user={instance?.user}
            size={images?.length === 1 ? size : undefined}
          />
        );
      })}
    </MultipleGroupImageContainer>
  );
}
ConversationImage.propTypes = {
  images: PropTypes.arrayOf({
    user: ProfilePicUserDataProps,
    observer: {
      lastSeen: PropTypes.number,
      status: PropTypes.string
    }
  }).isRequired,
  size: PropTypes.number
};
ConversationImage.defaultProps = {
  size: undefined
};

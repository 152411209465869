import useConversation from '@/hooks/useConversation';
import { Box, styled } from '@mui/material';
import React from 'react';
import ConversationImage from '../Header/ConversationImage';
const NoMessagesContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  gap: 10px;
`;
const DetailsWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap:5px;
  h6 {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #22262e;
    font-family: Inter, sans-serif;
  }
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    color: #8c96b1;
  }
`;
export default function NoMessages() {
  const { conversationImage, conversationName,conversationData } = useConversation();
  return (
    <NoMessagesContainer>
      <ConversationImage images={conversationImage} size={50} />
      <DetailsWrapper>
        <h6>{conversationName}</h6>
        <span>
          No conversations {conversationData?.isGroup ? 'in' : 'with'}{' '}
          <b>{conversationName}</b>
        </span>
      </DetailsWrapper>
    </NoMessagesContainer>
  );
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  severity: 'info', // accepted value info || warning || error || success
  alertMsg: '',
  alertOpen: false,
  footerLinkData: null
};

const globalSlice = createSlice({
  name: 'test',
  initialState,
  reducers: {
    // for alert only
    set_global_alertOpen: (state, { payload }) => {
      state.alertOpen = payload;
    },
    set_global_alertMsg: (state, { payload }) => {
      state.alertMsg = payload;
    },
    set_global_severity: (state, { payload }) => {
      state.severity = payload;
    },

    set_footerLinkData: (state, { payload }) => {
      state.footerLinkData = payload;
    }
  },
  extraReducers: {}
});

export const {
  set_global_alertOpen,
  set_global_alertMsg,
  set_global_severity,
  set_footerLinkData
} = globalSlice.actions;
export default globalSlice.reducer;

import useConversation from '@/hooks/useConversation';
import { MessageProps } from '@/hooks/useConversationProvider';
import ProfilePic from '@/ui/Profile/ProfilePic';
import { Box, styled } from '@mui/material';
import React from 'react';
import CommonMessageItem from './CommonMessageItem';

const MessageContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-bottom: 21px;
  display: flex;
  gap: 0.5rem;
  align-items: flex-end;
  .message-left {
    background: #fdfdfd;
    color: #8268ff;
  }
`;
export default function LeftMessage({ message }) {
  const { conversationUsers, conversationData } = useConversation();

  return (
    <MessageContainer>
      {conversationData?.isGroup && (
        <ProfilePic
          size={40}
          user={conversationUsers?.[message?.sender]?.data()}
        />
      )}
      <CommonMessageItem type="left" message={message} />
    </MessageContainer>
  );
}

LeftMessage.propTypes = MessageProps.isRequired;

import useConversation from '@/hooks/useConversation';
import useFirebase from '@/hooks/useFirebase';
import useOnScreen from '@/hooks/useOnScreen';
import { Box, Collapse, styled } from '@mui/material';
import React, { useEffect } from 'react';
import NoMessages from '../Fragments/NoMessages';
import LeftMessage from '../Messages/LeftMessage';
import RightMessage from '../Messages/RightMessage';
import InfiniteScroll from 'react-infinite-scroll-component';
import ModernCircleLoader from '@/ui/Loader/ModernCircleLoader';
import propTypes from 'prop-types';
const ChatViewContainer = styled(Box)``;
const MessagesContainer = styled(Box)`
  padding: 10px 10px 0 10px;
  position: relative;
`;
const LoaderContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 10px;
`;
export default function ChatView({ height }) {
  const { conversationMessages } = useConversation();
  const { currentUserId } = useFirebase();
  const [isVisible, setScrollBottomRef, scrollBottomRef] = useOnScreen();

  //initially set to bottom
  useEffect(() => {
    setTimeout(() => {
      scrollBottomRef?.scrollIntoView();
    }, 100);
  }, [scrollBottomRef]);

  //when chat is scrolled no need to scroll down
  useEffect(() => {
    if (isVisible && scrollBottomRef) {
      setTimeout(() => {
        scrollBottomRef?.scrollIntoView();
      }, 100);
    }
  }, [conversationMessages?.slice(-1)?.[0]?.id || '']);

  return (
    <ChatViewContainer height={height}>
      {conversationMessages?.length === 0 && <NoMessages />}
      <Collapse
        in={conversationMessages?.length > 0}
        unmountOnExit
        mountOnEnter
      >
        <InfiniteScroll
          dataLength={conversationMessages.length}
          next={() => console.log('fetching more data')}
          hasMore={conversationMessages.length > 20}
          loader={
            <LoaderContainer>
              <ModernCircleLoader size={20} />
            </LoaderContainer>
          }
          style={{ display: 'flex', flexDirection: 'column-reverse' }}
          height={height}
        >
          <MessagesContainer>
            {conversationMessages?.map((doc) => {
              const message = doc.data();
              if (message.sender === currentUserId) {
                return <RightMessage message={message} key={doc.id} />;
              }
              return <LeftMessage message={message} key={doc.id} />;
            })}
            <Box ref={setScrollBottomRef} />
          </MessagesContainer>
        </InfiniteScroll>
      </Collapse>
    </ChatViewContainer>
  );
}

ChatView.propTypes = {
  height: propTypes.oneOfType([propTypes.string, propTypes.number])
};
ChatView.defaultProps = {
  height:290,
};

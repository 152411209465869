import { getProfileImageUrl } from '@/lib/functions/helpers';
import { Avatar, Badge, styled, Tooltip } from '@mui/material';
import { useCallback } from 'react';
import propTypes from 'prop-types';

const StyledBadge = styled(Badge, {
  shouldForwardProp: (propName) => propName !== 'isOnline'
})(({ theme, isOnline }) => ({
  '& .MuiBadge-badge': isOnline
    ? {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          // animation: 'ripple 1.2s infinite ease-in-out',
          border: '1px solid currentColor',
          content: '""'
        }
      }
    : {},
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0
    }
  }
}));
const UserAvatar = styled(Avatar)`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: 700;
  color: white !important;
  -webkit-text-fill-color: white !important;
  text-transform: uppercase;
`;

function ProfilePic({
  user,
  isOnline,
  badgeContent,
  previewUrl,
  hideOverlap,
  className,
  title,
  backgroundColor,
  ...rest
}) {
  /* Generate random color #hash as per the string. */
  const stringToColor = useCallback((string) => {
    let hash = 0;
    let i = 0;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
  }, []);

  /* Get avatar image url/name. */
  const stringAvatar = useCallback(
    (user, previewUrl) => {
      if (!user) {
        return ``;
      }
      const name = `${user?.fullName}`;

      const $return = {
        sx: {
          backgroundColor:
            user?.profile_image || previewUrl
              ? 'white'
              : backgroundColor || stringToColor(name)
        }
      };

      $return.alt = `${name?.split(' ')?.[0]?.[0] || ''}${
        name?.split(' ')?.[1]?.[0] || ''
      }`;
      if (!user?.profile_image) {
        $return.children = $return.alt;
      } else {
        $return.src = user?.profile_image?.includes('http')
          ? user?.profile_image
          : getProfileImageUrl(user?.profile_image);
      }
      if (previewUrl) {
        $return.src = previewUrl;
      }

      return $return;
    },
    [backgroundColor, stringToColor]
  );

  return (
    <StyledBadge
      overlap={hideOverlap ? undefined : 'circular'}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      variant={isOnline ? 'dot' : undefined}
      badgeContent={badgeContent}
      isOnline={isOnline}
    >
      <Tooltip title={title || user?.fullName}>
        <UserAvatar
          {...rest}
          {...stringAvatar(user, previewUrl)}
          className={`profile-image ${className ?? ''}`}
        />
      </Tooltip>
    </StyledBadge>
  );
}
export const ProfilePicUserDataProps = propTypes.shape({
  fullName: propTypes.string.isRequired,
  profile_image: propTypes.string
});
ProfilePic.propTypes = {
  user: ProfilePicUserDataProps.isRequired,
  isOnline: propTypes.bool,
  hideOverlap: propTypes.bool,
  size: propTypes.number,
  fontSize: propTypes.number,
  badgeContent: propTypes.node,
  className: propTypes.string,
  previewUrl: propTypes.string,
  title: propTypes.string,
  backgroundColor: propTypes.string
};
ProfilePic.defaultProps = {
  isOnline: false,
  hideOverlap: false,
  size: 32,
  fontSize: 12,
  badgeContent: null,
  className: '',
  previewUrl: '',
  title: '',
  backgroundColor: ''
};
export default ProfilePic;

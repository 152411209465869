import { User_endpoint } from '@/api/endpoints';
import axiosInstance from 'Axios/axiosInstance';
import { useEffect, useRef, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useDebounce } from '../useDebounce';
import { onValue, ref } from 'firebase/database';
import { database } from '@/lib/functions/firebase';
import { getUserRealtimeConnectionObserverDatabaseReference } from '@/lib/functions/firebase.helpers';
export const ConnectionsTypeEnums = {
  accepted: 'Accepted',
  blocked: 'Blocked',
  received: 'Received',
  pending: 'Pending',
  canceled: 'Cancled',
  rejected: 'Rejected'
};
export default function useConnectionsList({
  enabled,
  type,
  extraParams,
  currentUserId
}) {
  const [searchValue, setSearchValue] = useState('');
  const initiatedRef = useRef(false);

  const debouncesSearchValue = useDebounce(searchValue, 300);

  const query = useInfiniteQuery(['connections', type, debouncesSearchValue], {
    queryFn: async () => {
      return axiosInstance
        .post(User_endpoint.friendList, {
          request_type: type,
          search: searchValue,
          ...extraParams
        })
        .then(({ data }) => {
          const tempConnections = {};
          data?.data?.map((connection) => {
            let accessor = null;
            if (connection?.sender_id === currentUserId) {
              accessor = connection?.receiver_id;
              connection.userData = connection?.receiver_user;
            }
            if (connection?.receiver_id === currentUserId) {
              accessor = connection?.sender_id;
              connection.userData = connection?.sender_user;
            }
            if (accessor) {
              tempConnections[accessor] = connection;
            }
          });
          return tempConnections;
        });
    },
    getNextPageParam: () => {
      //for future pagination
      return undefined;
    },
    getPreviousPageParam: () => {
      //for future pagination
      return undefined;
    },
    enabled,
    refetchOnWindowFocus: false
  });

  //message list listener for this type
  useEffect(() => {
    let unSubscribe = () => {};
    if (enabled && type && currentUserId) {
      unSubscribe = onValue(
        ref(
          database,
          getUserRealtimeConnectionObserverDatabaseReference(
            currentUserId,
            type
          )
        ),
        () => {
          if (query) {
            if (initiatedRef.current) {
              query.refetch();
            } else {
              initiatedRef.current = true;
            }
          }
        }
      );
    }
    return unSubscribe;
  }, [type, enabled, currentUserId]);

  return { ...query, setSearchValue, searchValue };
}

import React from 'react';
import propTypes from 'prop-types';
import { MessageProps } from '@/hooks/useConversationProvider';
import MessageTime from '../Fragments/MessageTime';
import { formatMessageTime } from '@/lib/functions/_common.lib';
import { Box, styled } from '@mui/material';
import { getUrlifyText } from '@/lib/functions/firebase.helpers';

const MessageContent = styled(Box)`
  border-radius: 20px;
  padding: 11px 16px;
  color: white;
  word-break: break-word;
  white-space: pre-line;
  a {
    text-decoration: underline;
  }
`;
export default function CommonMessageItem({ type, message }) {
  return (
    <Box>
      <MessageTime time={formatMessageTime(message.createdAt)} />
      <MessageContent
        className={`message-${type}`}
        dangerouslySetInnerHTML={{
          __html: getUrlifyText(message.content || '')
        }}
      />
    </Box>
  );
}
CommonMessageItem.propTypes = {
  type: propTypes.oneOf(['left', 'right']).isRequired,
  message: MessageProps.isRequired
};

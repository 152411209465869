import React from 'react';
import ChatHeader from '../../Header/ChatHeader';
import ChatView from '../../View/ChatView';
import ChatInput from '../../Input/ChatInput';
import { Box, Collapse, Fade, styled } from '@mui/material';
import useConversation from '@/hooks/useConversation';
const ChatPopupViewContainer = styled(Box)`
  background: #efefef;
  border-radius: 8px 8px 0 0;
  width: 300px;
  margin-right: 20px;
`;
const BodyWrapper = styled(Box)`
  border: 1px solid #d9d9d9;
`;

export default function ChatPopupView() {
  const { minimized } = useConversation();
  return (
    <Fade in unmountOnExit mountOnEnter>
      <ChatPopupViewContainer>
        <ChatHeader />
        <Collapse in={!minimized} unmountOnExit mountOnEnter>
          <BodyWrapper>
            <ChatView />
            <ChatInput />
          </BodyWrapper>
        </Collapse>
      </ChatPopupViewContainer>
    </Fade>
  );
}

ChatPopupView.propTypes = {};

import useConversation from '@/hooks/useConversation';
import { Box, Collapse, styled } from '@mui/material';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
const IsTypingContainer = styled(Box)`
  padding-bottom: 5px;
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 1;
    color: #969696;
    font-family: Inter, sans-serif;
  }
`;

const maxTypingUser = 3;
export default function IsTypingView(props) {
  const { observers } = useSelector((state) => state.chat);
  const { conversationId, getFilteredUsers } = useConversation();

  const typingUsers = useMemo(() => {
    return {
      name: Object.values(getFilteredUsers())
        ?.slice(0, maxTypingUser)
        ?.filter((user) => {
          return observers?.conversations?.[conversationId]?.isTyping?.[
            user?.id
          ];
        })
        ?.map((user) => user?.data()?.first_name)
        ?.join(', '),
      others:
        Object.keys(observers?.conversations?.[conversationId]?.isTyping || {})
          .length - maxTypingUser
    };
  }, [observers?.conversations?.[conversationId]?.isTyping]);

  return (
    <Collapse
      in={Boolean(typingUsers?.name)}
      unmountOnExit
      mountOnEnter
      timeout={90}
    >
      <IsTypingContainer {...props}>
        <span>
          {typingUsers?.name}{' '}
          {typingUsers?.others > 0 ? (
            <>& {typingUsers?.others} others</>
          ) : (
            ' is'
          )}{' '}
          typing ...
        </span>
      </IsTypingContainer>
    </Collapse>
  );
}

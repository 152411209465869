import { Box, IconButton, styled, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import useConversation from '@/hooks/useConversation';
import {
  getConversationReference,
  getServerTimeStamp
} from '@/lib/functions/firebase.helpers';
import { addDoc, collection, doc, increment, setDoc } from 'firebase/firestore';
import { database, firestore } from '@/lib/functions/firebase';
import {
  FIRESTORE_CONVERSATIONS_COLLECTION,
  FIRESTORE_MESSAGES_COLLECTION
} from '@/lib/functions/constants';
import { useDebounce } from '@/hooks/useDebounce';
import { onDisconnect, ref, remove, set } from 'firebase/database';
import useFirebase from '@/hooks/useFirebase';
import IsTypingView from './IsTypingView';
const ChatInputContainer = styled(Box)`
  background: #fff;
  border: 1px solid #c8c8c8;
  border-radius: 8px;
  display: flex;
  padding: 5px 10px;
  align-items: flex-end;
`;
const ChatInputWrapper = styled(Box)`
  padding: 5px 13px 13px 13px;
`;
const AddExtraButton = styled(IconButton)`
  background: #2e05f8;
  margin-bottom: 4px;
  width: 23px;
  height: 23px;
  border: none;
  border-radius: 8px 8px 8px 0;
  text-align: center;
  svg {
    color: #fff;
  }
  :hover {
    background: #2e05f8;
  }
`;
const SendMessageButton = styled(IconButton)`
  svg {
    color: #2e05f8;
  }
`;

const TextInputBox = styled(TextField)`
  flex-grow: 1;
  padding: 0 5px;
  .MuiInputBase-root {
    border: none;
    &::after {
      border-bottom: none !important;
    }
    &::before {
      border-bottom: none !important;
    }
  }
`;
export const TypingsEnum = {
  typing: 'typing',
  stopped: 'stopped'
};
export default function ChatInput() {
  const [sending, setSending] = useState(false);
  const { conversationId, conversationData, conversationInitialData } =
    useConversation();
  const { currentUserId, createSingleConversation } = useFirebase();

  // const [filePreviews, setFilePreviews] = useState([{ url: '', file: null }]); // [{url:"",file:File}]
  const [inputValue, setInputValue] = useState('');
  const [typing, setTyping] = useState(TypingsEnum.stopped);

  const setTypingStopped = useCallback(() => {
    setTyping(TypingsEnum.stopped);
  }, []);

  //set typing stop if no input found
  useDebounce(inputValue, 2500, setTypingStopped);

  //conversation typing listener
  useEffect(() => {
    let unSub = () => {};
    if (conversationId) {
      const referencePath = `${getConversationReference(
        conversationId
      )}/isTyping/${currentUserId}`;

      if (typing) {
        const reference = ref(database, referencePath);
        if (typing == TypingsEnum.typing) {
          set(reference, true);
        }
        if (typing == TypingsEnum.stopped) {
          remove(reference);
        }
        onDisconnect(reference).remove();
        unSub = () => {
          remove(reference);
        };
      }
    }
    return unSub;
  }, [typing]);

  //input value change
  const changeInputValue = useCallback(({ target: { value } }) => {
    setTyping(TypingsEnum.typing);
    setInputValue(value);
  }, []);

  //update conversation timing seen and unread count
  const updateConversation = useCallback(
    (conversationId, message) => {
      const tempUnreadCount = {};
      conversationData?.users?.map((userId) => {
        tempUnreadCount[`${userId}`] =
          userId != message?.sender ? increment(1) : 0;
      });
      setDoc(
        doc(firestore, FIRESTORE_CONVERSATIONS_COLLECTION, conversationId),
        {
          unreadCount: tempUnreadCount,
          seen: {
            [`${message?.sender}`]: {
              lastMessageId: message?.messageId,
              seenAt: getServerTimeStamp()
            }
          },
          updatedAt: getServerTimeStamp()
        },
        {
          merge: true
        }
      );
    },
    [conversationData]
  );

  //before message sent
  const beforeMessageSent = useCallback(() => {
    setInputValue('');
  }, []);

  //after message is sent
  const afterMessageSent = useCallback(
    (conversationId, sendData) => {
      setSending(false);
      updateConversation(conversationId, sendData);
      setTypingStopped();
    },
    [updateConversation, setTypingStopped]
  );
  //send message
  const sendMessage = useCallback(
    async (conversationId) => {
      const sendData = {
        sender: currentUserId,
        content: inputValue,
        type: 'text',
        createdAt: getServerTimeStamp(),
        updatedAt: getServerTimeStamp()
      };

      beforeMessageSent(conversationId, sendData);
      const messageRef = await addDoc(
        collection(
          firestore,
          FIRESTORE_CONVERSATIONS_COLLECTION,
          conversationId,
          FIRESTORE_MESSAGES_COLLECTION
        ),
        sendData
      );
      sendData['messageId'] = messageRef.id;
      afterMessageSent(conversationId, sendData, messageRef);
    },
    [afterMessageSent, beforeMessageSent, currentUserId, inputValue]
  );

  //if conversation id not found create conversation other wise send it
  const determineSendOrCreateSend = useCallback(() => {
    if (sending || !inputValue.trim()) {
      return null;
    }
    setSending(true);
    if (!conversationId) {
      return createSingleConversation({
        users: [conversationInitialData?.userId]
      }).then((conversationRef) => {
        sendMessage(conversationRef.id);
      });
    } else {
      return sendMessage(conversationId);
    }
  }, [
    conversationId,
    createSingleConversation,
    sendMessage,
    sending,
    inputValue,
    conversationInitialData
  ]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 13 && !event.shiftKey) {
        determineSendOrCreateSend();
        event.preventDefault();
      }
    },
    [determineSendOrCreateSend]
  );

  return (
    <ChatInputWrapper>
      <IsTypingView />
      <ChatInputContainer component="form" onSubmit={determineSendOrCreateSend}>
        <AddExtraButton size="small">
          <AddIcon />
        </AddExtraButton>
        <TextInputBox
          placeholder="write message"
          variant="standard"
          multiline
          maxRows={3}
          value={inputValue}
          onChange={changeInputValue}
          onKeyDown={handleKeyDown}
        />
        <SendMessageButton size="small" onClick={determineSendOrCreateSend}>
          <SendIcon />
        </SendMessageButton>
      </ChatInputContainer>
    </ChatInputWrapper>
  );
}

import { User_endpoint } from '@/api/endpoints';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'Axios/axiosInstance';

export const forum_topic_list = createAsyncThunk(
  User_endpoint.forumTopiclist,
  async (data) => {
    try {
      const res = await axiosInstance.post(User_endpoint.forumTopiclist, data);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const forum_topic_details = createAsyncThunk(
  User_endpoint.forumTopicdetails,
  async (data) => {
    try {
      const res = await axiosInstance.post(
        User_endpoint.forumTopicdetails,
        data
      );

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const forum_topic_commentadd = createAsyncThunk(
  User_endpoint.commentpost,
  async (data) => {
    try {
      const res = await axiosInstance.post(User_endpoint.commentpost, data);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const forum_topic_commentlisting = createAsyncThunk(
  User_endpoint.commentlist,
  async (data) => {
    try {
      const res = await axiosInstance.post(User_endpoint.commentlist, data);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const recenttopic_category = createAsyncThunk(
  User_endpoint.forumrecenttopic,
  async (data) => {
    try {
      const res = await axiosInstance.post(
        User_endpoint.forumrecenttopic,
        data
      );

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const post_search = createAsyncThunk(
  User_endpoint.searchforumlist,
  async (data) => {
    try {
      const res = await axiosInstance.post(User_endpoint.searchforumlist, data);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);


export const Topiclist_user = createAsyncThunk(
  User_endpoint.topiclistbyuser,
  async (data) => {
    try {
      const res = await axiosInstance.post(User_endpoint.topiclistbyuser, data);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);



const initialState = {
  forum_topic_list_status: 'idle',
  forum_topic_list_data: [],
  forum_topic_list_total: 0,
  forum_topic_list_total_pages: 0,
  forum_topic_details_data: [],
  allPostFetched: false,
  forum_topic_commentlisting_data: [],
  recenttopic_category_data: [],
  forum_topic_list_data1: [],
  forum_topic_list_data2: [],
  post_search_data: [],
  topiclist_user_data:[],
};

const postSlice = createSlice({
  name: 'postSlice',
  initialState,
  reducers: {
    increment: (state) => {
      state.count += 1;
    },
    reset_forum_topic_list: (state) => {
      state.forum_topic_list_data = [];
    }
  },
  extraReducers: {
    // forum_topic_list Section
    [forum_topic_list.pending]: (state) => {
      state.forum_topic_list_status = 'loading';
    },
    [forum_topic_list.fulfilled]: (state, { payload }) => {
      state.forum_topic_list_status = 'idle';

      state.forum_topic_list_data1 = payload?.data;
      state.forum_topic_list_data2 = payload?.data;
      if (payload?.data) {
        if (
          payload?.data?.docs?.length &&
          state.forum_topic_list_data?.length <= payload?.data?.total
        ) {
          state.forum_topic_list_total_pages = payload?.data?.pages;
          state.forum_topic_list_total = payload?.data?.total;

          state.forum_topic_list_data = [
            ...state.forum_topic_list_data,
            ...payload?.data?.docs
          ];

          state.allPostFetched = false;
        } else {
          state.allPostFetched = true;
        }
      } else {
        state.forum_topic_list_data = [];
      }
    },
    [forum_topic_list.rejected]: (state) => {
      state.forum_topic_list_status = 'idle';
    },

    // forum_topic_details section
    [forum_topic_details.pending]: (state) => {
      state.forum_topic_details_status = 'loading';
    },
    [forum_topic_details.fulfilled]: (state, { payload }) => {
      state.forum_topic_details_status = 'idle';

      state.forum_topic_details_data = payload?.data;
    },
    [forum_topic_details.rejected]: (state) => {
      state.forum_topic_details_status = 'idle';
    },

    // forum_topic_commentadd section

    [forum_topic_commentadd.pending]: (state) => {
      state.forum_topic_commentapp_status = 'loading';
    },
    [forum_topic_commentadd.fulfilled]: (state, { payload }) => {
      state.forum_topic_commentapp_status = 'idle';

      state.forum_topic_commentapp_data = payload?.data;
    },
    [forum_topic_commentadd.rejected]: (state) => {
      state.forum_topic_commentapp_status = 'idle';
    },

    // post_search section
    [post_search.pending]: (state) => {
      state.post_search_status = 'loading';
    },
    [post_search.fulfilled]: (state, { payload }) => {
      state.post_search_status = 'idle';

      state.post_search_data = payload?.data;
    },
    [post_search.rejected]: (state) => {
      state.post_search_status = 'idle';
    },

    // recenttopic_category section

    [recenttopic_category.pending]: (state) => {
      state.recenttopic_category_status = 'loading';
    },
    [recenttopic_category.fulfilled]: (state, { payload }) => {
      state.recenttopic_category_status = 'idle';

      state.recenttopic_category_data = payload?.data;
    },
    [recenttopic_category.rejected]: (state) => {
      state.recenttopic_category_status = 'idle';
    },



    // Topiclist_user section


    [Topiclist_user.pending]: (state) => {
      state.topiclist_user_status = 'loading';
    },
    [Topiclist_user.fulfilled]: (state, { payload }) => {
      state.topiclist_user_status = 'idle';

      state.topiclist_user_data = payload?.data;
    },
    [Topiclist_user.rejected]: (state) => {
      state.topiclist_user_status = 'idle';
    },










    // forum_topic_commentlisting section

    [forum_topic_commentlisting.pending]: (state) => {
      state.forum_topic_commentlisting_status = 'loading';
    },
    [forum_topic_commentlisting.fulfilled]: (state, { payload }) => {
      state.forum_topic_commentlisting_status = 'idle';

      state.forum_topic_commentlisting_data = payload?.data;
    },
    [forum_topic_commentlisting.rejected]: (state) => {
      state.forum_topic_commentlisting_status = 'idle';
    }
  }
});

export const { reset_forum_topic_list } = postSlice.actions;
export default postSlice.reducer;

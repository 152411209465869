import useConversationProvider, {
  ConversationData
} from '@/hooks/useConversationProvider';
import propType from 'prop-types';
import React, { createContext } from 'react';
export const ConversationContext = createContext(null);

export default function ConversationProvider({ children, conversation }) {
  const conversationProvider = useConversationProvider(conversation);
  return (
    <ConversationContext.Provider value={conversationProvider}>
      {children}
    </ConversationContext.Provider>
  );
}

ConversationProvider.propTypes = {
  children: propType.node.isRequired,
  conversation: propType.shape({
    id: propType.string.isRequired,
    conversationId: propType.string,
    userId: propType.string,
    data: ConversationData
  }).isRequired
};
export const FirebaseConsumer = ConversationContext.Consumer;

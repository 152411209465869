import { kebabCase } from 'lodash';
import moment from 'moment';

export const formatFileName = (name) => {
  const splitted = name.split('.');

  const extension = splitted.slice(-1)[0];
  const baseName = splitted.slice(0, -1).join('.');

  return `${Date.now()}-${kebabCase(
    baseName
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/đ/g, 'd')
      .replace(/Đ/g, 'D')
  )}.${extension}`;
};

export const formatFileSize = (size) => {
  if (size <= 0) {
    return `${size} B`;
  }
  const i = Math.floor(Math.log(size) / Math.log(1024));

  return `${(size / 1024 ** i).toFixed(1)} ${['B', 'KB', 'MB', 'GB', 'TB'][i]}`;
};

export const formatDate = (timestamp) => {
  const momentDate = moment(timestamp);
  const todaysDate = moment(new Date());
  if (momentDate.isSame(todaysDate.startOf('day'), 'd')) {
    return momentDate.format('hh:mm A');
  }

  if (momentDate.isSame(todaysDate.subtract(1, 'days').startOf('day'), 'd')) {
    return 'Yesterday';
  }

  if (momentDate.isAfter(todaysDate.subtract(7, 'days').startOf('day'), 'd')) {
    return momentDate.format('dddd');
  }
  return momentDate.format('DD-MM-YYYY');
};

export const formatMessageTime = (serverTimestamp) => {
  return moment(
    serverTimestamp?.seconds ? serverTimestamp.seconds * 1000 : Date.now()
  ).format('hh:mm A');
};

export const formatDateMessageGrouping = (date) => {
  const momentDate = moment(date, 'YYYY-MM-DD');
  const todaysDate = moment(new Date());
  if (momentDate.isSame(todaysDate.startOf('day'), 'd')) {
    return 'Today';
  }
  if (momentDate.isSame(todaysDate.subtract(1, 'days').startOf('day'), 'd')) {
    return 'Yesterday';
  }
  if (momentDate.isAfter(todaysDate.subtract(7, 'days').startOf('day'), 'd')) {
    return momentDate.format('dddd');
  }
  return momentDate.format('LL');
};

export const splitLinkFromMessage = (message) => {
  const URL_REGEX =
    /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\\+.~#?&\\/\\/=]*)/gm;

  const result = message.split(' ').reduce((acc, item) => {
    const isURL = URL_REGEX.test(item);
    if (isURL) {
      acc.push({ link: item });
    } else if (typeof acc.slice(-1)[0] === 'string') {
      acc = [...acc.slice(0, -1), `${acc.slice(-1)[0]} ${item}`];
    } else {
      acc.push(item);
    }

    return acc;
  }, []);

  return result;
};

export const renderViewMap = (item) => {
  if (
    item?.userbaselogs?.isApproved?.toLowerCase() === 'approved' &&
    item?.userbaselogs?.isCurrent
  ) {
    return false;
  }

  if (item?.userbaselogs?.isApproved?.toLowerCase() === 'pending') {
    return false;
  }

  return true;
};

import ChatPopupsStack from '@/components/Chat/ChatBox/ChatPopupsStack';
import useFirebaseProvider from '@/hooks/useFirebaseProvider';
import propType from 'prop-types';
import React, { createContext } from 'react';
export const FirebaseContext = createContext(null);

export default function FirebaseProvider({ children }) {
  const firebaseProvider = useFirebaseProvider();
  return (
    <FirebaseContext.Provider value={firebaseProvider}>
      {children}
      <ChatPopupsStack />
    </FirebaseContext.Provider>
  );
}

FirebaseProvider.propTypes = {
  children: propType.node.isRequired
};
export const FirebaseConsumer = FirebaseContext.Consumer;

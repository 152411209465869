import axiosInstance from 'Axios/axiosInstance';
import { cms_end_point } from '../endpoints';
// import { cms_end_point } from '../endpoints';

export async function fetch_cmsData({ endPoint, data, method }) {
  try {
    const config = {
      method,
      url: endPoint,
      data
    };

    const { data: response } = await axiosInstance(config);

    if (response) {
      return { data: response };
    } else {
      return {
        data: null,
        status: 500,
        message: 'Something wentt wrong'
      };
    }
  } catch {
    return {
      data: null,
      status: 500,
      message: 'Something wentt wrong'
    };
  }
}

export async function fetch_SocialLinks() {
  try {
    const config = {
      method: 'get',
      url: cms_end_point.seting
    };

    const { data: response } = await axiosInstance(config);

    if (response) {
      return { data: response };
    } else {
      return {
        data: null,
        status: 500,
        message: 'Something wentt wrong'
      };
    }
  } catch {
    return {
      data: null,
      status: 500,
      message: 'Something wentt wrong'
    };
  }
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeConversations: [],
  observers: {
    users: {},
    conversations: {}
  }
};
// users = {
//   status:UserStatusEnums,
//   lastSeen:timestamp
// }

//structure
// activeConversations = [
//   {
//     id: 'asdasdsada',
//     conversationId: 'aasasd',
//     userId: 'asadasda',
//     conversationData: {},
//   }
// ];

export const generateOpenConversationData = ({
  userId,
  conversationId,
  data
}) => {
  return {
    id: conversationId || userId,
    conversationId,
    userId,
    conversationData: data || {}
  };
};
const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addConversationToActive: (state, { payload }) => {
      ///payload must have params of generateOpenConversationData,
      const conversationData = generateOpenConversationData(payload);
      const index = state.activeConversations.findIndex(
        (conversation) =>
          conversation?.id === conversationData?.id ||
          conversation?.userId === conversationData?.id ||
          conversation?.conversationId === conversationData?.id
      );
      if (index < 0) {
        state.activeConversations.push(conversationData);
      }
    },
    removeConversationFromActive: (state, { payload }) => {
      ///payload must be a unique id,
      const index = state.activeConversations.findIndex(
        (conversation) =>
          conversation?.id === payload ||
          conversation?.userId === payload ||
          conversation?.conversationId === payload
      );
      if (index > -1) {
        state.activeConversations.splice(index, 1);
      }
    },
    updateUserObserver: (state, { payload }) => {
      state.observers.users = {
        ...state.observers.users,
        [payload?.id]: payload
      };
    },
    updateConversationObserver: (state, { payload }) => {
      state.observers.conversations = {
        ...state.observers.conversations,
        [payload?.conversationId]: payload?.value
      };
    }
  },
  extraReducers: {}
});

export const {
  addConversationToActive,
  removeConversationFromActive,
  updateConversationObserver,
  updateUserObserver
} = chatSlice.actions;
export default chatSlice;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { toast } from 'react-toastify';
import axiosInstance from '../Axios/axiosInstance';
import { cms_end_point, User_endpoint } from '../API/endpoints/index';
import { Cookies } from 'react-cookie';
import { updateUserProfile } from '@/api/Functions/auth.api';

const cookie = new Cookies();
const initialState = {
  status: 'idle',
  User_details_data: [],
  User_details_status: 'idle',
  successfull_Login_status: 'idle',
  User_details_update_status: null,
  About_policy_data: [],
  Affilationcms_data: [],
  terms_condition_data: [],
  verify_email_data: '',
  forum_List_data: [],
  forum_topic_list_data: [],
  Featurecategory_details_data: [],

  SubscriptionCMSData: [],
  User_subscriptioplanlist_data: [],
  User_subscription_data: [],
  User_restartautorenewal_data:[],
};

//  feturedcategory_details Section
export const Featurecategory_details = createAsyncThunk(
  User_endpoint.Contact_us,
  async (data) => {
    try {
      const res = await axiosInstance.post(
        User_endpoint.featurecategory_deatils,
        data
      );

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

//  Contact_us Section
export const Contact_us = createAsyncThunk(
  User_endpoint.Contact_us,
  async (data) => {
    try {
      const res = await axiosInstance.post(User_endpoint.Contact_us, data);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

//  Signup Section
export const Sign_up = createAsyncThunk(User_endpoint.Signup, async (data) => {
  try {
    const res = await axiosInstance.post(User_endpoint.Signup, data);

    return res?.data;
  } catch (error) {
    console.log(error);
  }
});

//  Login Section
export const Login = createAsyncThunk(User_endpoint.Login, async (data) => {
  try {
    const res = await axiosInstance.post(User_endpoint.Login, data);

    return res?.data;
  } catch (error) {
    console.log(error);
  }
});

//  User Details Section
export const User_details = createAsyncThunk(
  User_endpoint.Userdetails,
  async (data) => {
    try {
      const res = await axiosInstance.get(User_endpoint.Userdetails, data);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);
//  User subscription details Section
export const User_subscriptioDetails = createAsyncThunk(
  User_endpoint.subscription_plandetails,
  async (data) => {
    try {
      const res = await axiosInstance.get(
        cms_end_point.subscription_plandetails,
        data
      );

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

// User_subscriptioplanlist

export const User_subscriptioplanlist = createAsyncThunk(
  cms_end_point.subscription_plan,
  async (data) => {
    try {
      const res = await axiosInstance.get(
        cms_end_point.subscription_plan,
        data
      );

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

//  User subscription plan list Section
export const User_subscriptioPlanlist = createAsyncThunk(
  User_endpoint.subscription_plandetails,
  async (data) => {
    try {
      const res = await axiosInstance.get(
        cms_end_point.subscription_plan,
        data
      );

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

// autorenewalcencle

export const User_autorenewalCencle = createAsyncThunk(
  User_endpoint.autorenewalcencle,
  async (data) => {
    try {
      const res = await axiosInstance.get(
        User_endpoint.autorenewalcencle,
        data
      );

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

// restart autorenewal

export const User_restartautorenewal = createAsyncThunk(
  User_endpoint.restartautorenewal,
  async (data) => {
    try {
      const res = await axiosInstance.get(
        User_endpoint.restartautorenewal,
        data
      );

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

// User subscriptionCMS details Section

export const subscriptionCMS = createAsyncThunk(
  User_endpoint.subscription,
  async (data) => {
    try {
      const res = await axiosInstance.get(cms_end_point.subscription, data);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

//  User_password_change Section
export const User_password_change = createAsyncThunk(
  User_endpoint.chnagepassword,
  async (data) => {
    try {
      const res = await axiosInstance.post(User_endpoint.chnagepassword, data);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

//  User_forgot_pass Section
export const User_forgot_pass = createAsyncThunk(
  User_endpoint.forgotpassword,
  async (data) => {
    try {
      const res = await axiosInstance.post(User_endpoint.forgotpassword, data);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

//  User Details update Section
export const User_details_update = createAsyncThunk(
  User_endpoint.userupdate,
  async (data) => {
    try {
      const res = await updateUserProfile({
        endPoint: User_endpoint.userupdate,
        data,
        method: 'POST'
      });

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const About_policy = createAsyncThunk(
  User_endpoint.aboutPolicy,
  async (data) => {
    try {
      const res = await axiosInstance.get(User_endpoint.aboutPolicy, data);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const forum_List = createAsyncThunk(
  User_endpoint.forumlist,
  async (data) => {
    try {
      const res = await axiosInstance.post(User_endpoint.forumlist, data);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const forum_create = createAsyncThunk(
  User_endpoint.forumcreate,
  async (data) => {
    try {
      const res = await axiosInstance.post(User_endpoint.forumcreate, data);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const change_subscription = createAsyncThunk(
  User_endpoint.changeSubscription,
  async (data) => {
    try {
      const res = await axiosInstance.post(
        User_endpoint.changeSubscription,
        data
      );

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const restart_subscription = createAsyncThunk(
  User_endpoint.restartmembershot,
  async (data) => {
    try {
      const res = await axiosInstance.post(
        User_endpoint.restartmembershot,
        data
      );

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);






export const Affilationcms = createAsyncThunk(
  User_endpoint.affilation_cms,
  async (data) => {
    try {
      const res = await axiosInstance.get(User_endpoint.affilation_cms, data);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const terms_condition = createAsyncThunk(
  User_endpoint.termsandcondition,
  async (data) => {
    try {
      const res = await axiosInstance.get(
        User_endpoint.termsandcondition,
        data
      );

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const forgot_password = createAsyncThunk(
  User_endpoint.forgortpassword,
  async (data) => {
    try {
      const res = await axiosInstance.post(User_endpoint.forgortpassword, data);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const Verify_OTP = createAsyncThunk(
  User_endpoint.verifyOTP,
  async (data) => {
    try {
      const res = await axiosInstance.post(User_endpoint.verifyOTP, data);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const reset_Password = createAsyncThunk(
  User_endpoint.resetPassword,
  async (data) => {
    try {
      const res = await axiosInstance.post(User_endpoint.resetPassword, data);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const user_delete = createAsyncThunk(
  User_endpoint.userDelete,
  async (data) => {
    try {
      const res = await axiosInstance.get(User_endpoint.userDelete, data);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const UserSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    removeAll: (state) => {
      state = initialState;
    },
    verify_email: (state, action) => {
      state.verify_email_data = action?.payload;
    }
  },
  extraReducers: {
    [Sign_up.pending]: (state) => {
      state.status = 'loading';

      state.successfull_Sign_up_status = false;
    },
    [Sign_up.fulfilled]: (state, { payload }) => {
      if (payload?.status === 200) {
        cookie.set('token', payload?.token);
        state.successfull_Sign_up_status = true;
      } else {
        state.successfull_Sign_up_status = false;
      }
      state.status = 'idle';
    },
    [Sign_up.rejected]: (state) => {
      state.status = 'idle';
    },

    // Login Section
    [Login.pending]: (state) => {
      state.status = 'loading';
      state.successfull_Login_status = false;
    },
    [Login.fulfilled]: (state, { payload }) => {
      if (payload?.status === 200) {
        cookie.set('token', payload?.token);
        state.successfull_Login_status = true;
      } else {
        state.successfull_Login_status = false;
      }
      state.status = 'idle';
    },
    [Login.rejected]: (state) => {
      state.status = 'idle';
    },

    // User_details Section
    [User_details.pending]: (state) => {
      state.User_details_status = 'loading';
    },
    [User_details.fulfilled]: (state, { payload }) => {
      state.User_details_status = 'idle';
      // if (payload?.type === 'success') {
      // cookie.get('token');
      state.User_details_data = payload?.data;

      // state.User_details_data = payload?.data;
      // } else {
      //   state.User_details_data = 'no data';
      // }
    },
    [User_details.rejected]: (state) => {
      state.User_details_status = 'idle';
    },

    // SubscriptionCMSData

    [subscriptionCMS.pending]: (state) => {
      state.SubscriptionCMSData_status = 'loading';
    },
    [subscriptionCMS.fulfilled]: (state, { payload }) => {
      state.SubscriptionCMSData_status = 'idle';

      state.SubscriptionCMSData = payload?.data;
    },
    [subscriptionCMS.rejected]: (state) => {
      state.SubscriptionCMSData_status = 'idle';
    },

    // User_details_update Section
    [User_details_update.pending]: (state) => {
      state.User_details_update_status = null;
    },
    [User_details_update.fulfilled]: (state, { payload }) => {
      if (payload?.status == 200) {
        state.User_details_update_status = true;
        state.User_details_data = payload?.data;
      } else {
        state.User_details_update_status = null;
        state.status = 'idle';
      }
    },
    [User_details_update.rejected]: (state) => {
      state.status = 'idle';
    },

    // User_password_change

    [User_password_change.pending]: (state) => {
      state.User_password_change_status = null;
    },
    [User_password_change.fulfilled]: (state, { payload }) => {
      if (payload?.status == 200) {
        state.User_password_change_status = true;
        // state.User_details_data = payload?.data;
      } else {
        state.User_password_change_status = null;
        state.status = 'idle';
      }
    },
    [User_password_change.rejected]: (state) => {
      state.status = 'idle';
    },

    // User_forgot_password section

    [User_forgot_pass.pending]: (state) => {
      state.User_password_change_status = null;
    },
    [User_forgot_pass.fulfilled]: (state, { payload }) => {
      if (payload?.status == 200) {
        state.User_password_change_status = true;
        // state.User_details_data = payload?.data;
      } else {
        state.User_password_change_status = null;
        state.status = 'idle';
      }
    },
    [User_forgot_pass.rejected]: (state) => {
      state.status = 'idle';
    },

    // user_delete section

    [user_delete.pending]: (state) => {
      state.user_delete_change_status = null;
    },
    [user_delete.fulfilled]: (state, { payload }) => {
      if (payload?.status == 200) {
        state.user_delete_change_status = true;
        // state.User_details_data = payload?.data;
      } else {
        state.user_delete_change_status = null;
        state.status = 'idle';
      }
    },
    [user_delete.rejected]: (state) => {
      state.status = 'idle';
    },

    // User_forgot_password  and Verify_OTP section

    [Verify_OTP.pending]: (state) => {
      state.Verify_OTP_status = null;
    },
    [Verify_OTP.fulfilled]: (state, { payload }) => {
      if (payload?.status == 200) {
        state.Verify_OTP_status = true;
        // state.User_details_data = payload?.data;
      } else {
        state.Verify_OTP_status = null;
        state.status = 'idle';
      }
    },
    [Verify_OTP.rejected]: (state) => {
      state.status = 'idle';
    },

    // forgot reset_Password section

    [reset_Password.pending]: (state) => {
      state.reset_Password_status = null;
    },
    [reset_Password.fulfilled]: (state, { payload }) => {
      if (payload?.status == 200) {
        state.reset_Password_status = true;
        // state.User_details_data = payload?.data;
      } else {
        state.reset_Password_status = null;
        state.status = 'idle';
      }
    },
    [reset_Password.rejected]: (state) => {
      state.status = 'idle';
    },

    // Contact_us Section

    [Contact_us.pending]: (state) => {
      state.Contact_us_status = null;
    },
    [Contact_us.fulfilled]: (state, { payload }) => {
      if (payload?.status == 200) {
        state.Contact_us_status = true;
        // state.User_details_data = payload?.data;
      } else {
        state.Contact_us_status = null;
        state.status = 'idle';
      }
    },
    [Contact_us.rejected]: (state) => {
      state.status = 'idle';
    },
    // forum_create Section

    [forum_create.pending]: (state) => {
      state.forum_create_status = null;
    },
    [forum_create.fulfilled]: (state, { payload }) => {
      if (payload?.status == 200) {
        state.forum_create_status = true;
        // state.User_details_data = payload?.data;
      } else {
        state.forum_create_status = null;
        state.status = 'idle';
      }
    },
    [forum_create.rejected]: (state) => {
      state.status = 'idle';
    },

    // change_subscription section

    [change_subscription.pending]: (state) => {
      state.status = 'loading';
      state.change_subscription_status = null;
    },
    [change_subscription.fulfilled]: (state, { payload }) => {
      if (payload?.status == 200) {
        state.forum_create_status = true;
        // state.User_details_data = payload?.data;
      } else {
        state.change_subscription_status = null;
        state.status = 'idle';
      }
    },
    [change_subscription.rejected]: (state) => {
      state.status = 'idle';
    },

    









    // restart_subscription
    [restart_subscription.pending]: (state) => {
      state.restart_subscription_status = null;
    },
    [restart_subscription.fulfilled]: (state, { payload }) => {
      if (payload?.status == 200) {
        state.forum_create_status = true;
        // state.User_details_data = payload?.data;
      } else {
        state.restart_subscription_status = null;
        state.status = 'idle';
      }
    },
    [restart_subscription.rejected]: (state) => {
      state.status = 'idle';
    },







    // forgot_password Section

    [forgot_password.pending]: (state) => {
      state.forgot_password_status = null;
    },
    [forgot_password.fulfilled]: (state, { payload }) => {
      if (payload?.status == 200) {
        state.forgot_password_status = true;
        // state.User_details_data = payload?.data;
      } else {
        state.forgot_password_status = null;
        state.status = 'idle';
      }
    },
    [forgot_password.rejected]: (state) => {
      state.status = 'idle';
    },

    // Affilationcms section
    [Affilationcms.pending]: (state) => {
      state.Affilationcms_status = 'loading';
    },
    [Affilationcms.fulfilled]: (state, { payload }) => {
      state.Affilationcms_status = 'idle';

      state.Affilationcms_data = payload?.data;
    },
    [Affilationcms.rejected]: (state) => {
      state.Affilationcms_status = 'idle';
    },

    // forum_List section
    [forum_List.pending]: (state) => {
      state.forum_List_status = 'loading';
    },
    [forum_List.fulfilled]: (state, { payload }) => {
      state.forum_List_status = 'idle';

      state.forum_List_data = payload?.data;
    },
    [forum_List.rejected]: (state) => {
      state.forum_List_status = 'idle';
    },

    // Affilationcms section
    [About_policy.pending]: (state) => {
      state.About_policy_status = 'loading';
    },
    [About_policy.fulfilled]: (state, { payload }) => {
      state.About_policy_status = 'idle';

      state.About_policy_data = payload?.data;
    },
    [About_policy.rejected]: (state) => {
      state.About_policy_status = 'idle';
    },

    //Featurecategory_details

    [Featurecategory_details.pending]: (state) => {
      state.Featurecategory_details_status = 'loading';
    },
    [Featurecategory_details.fulfilled]: (state, { payload }) => {
      state.Featurecategory_details_status = 'idle';

      state.Featurecategory_details_data = payload?.data;
    },
    [Featurecategory_details.rejected]: (state) => {
      state.Featurecategory_details_status = 'idle';
    },

    // User_subscription

    [User_subscriptioDetails.pending]: (state) => {
      state.User_subscription_status = 'loading';
    },
    [User_subscriptioDetails.fulfilled]: (state, { payload }) => {
      state.User_subscription_status = 'idle';

      state.User_subscription_data = payload?.data;
    },
    [User_subscriptioDetails.rejected]: (state) => {
      state.User_details_status = 'idle';
    },

    // User_subscriptioplanlist

    [User_subscriptioplanlist.pending]: (state) => {
      state.User_subscriptioplanlist_status = 'loading';
    },
    [User_subscriptioplanlist.fulfilled]: (state, { payload }) => {
      state.User_subscriptioplanlist_status = 'idle';

      state.User_subscriptioplanlist_data = payload?.data;
    },
    [User_subscriptioplanlist.rejected]: (state) => {
      state.User_details_status = 'idle';
    },

    // User_autorenewalCencle
    [User_autorenewalCencle.pending]: (state) => {
      state.User_autorenewalCencle_status = 'loading';
    },
    [User_autorenewalCencle.fulfilled]: (state, { payload }) => {
      state.User_autorenewalCencle_status = 'idle';

      state.User_autorenewalCencle_data = payload?.data;
    },
    [User_autorenewalCencle.rejected]: (state) => {
      state.User_details_status = 'idle';
    },

    //User_restartautorenewal
    [User_restartautorenewal.pending]: (state) => {
       // state.status = 'loading';
      state.User_restartautorenewal_status = 'loading';
    },
    [User_restartautorenewal.fulfilled]: (state, { payload }) => {
      state.User_restartautorenewal_status = 'idle';

      state.User_restartautorenewal_data = payload?.data;
    },
    [User_restartautorenewal.rejected]: (state) => {
      state.User_details_status = 'idle';
    },

    // terms_condition section
    [terms_condition.pending]: (state) => {
      state.terms_condition_status = 'loading';
    },
    [terms_condition.fulfilled]: (state, { payload }) => {
      state.terms_condition_status = 'idle';

      state.terms_condition_data = payload?.data;
    },
    [terms_condition.rejected]: (state) => {
      state.terms_condition_status = 'idle';
    }
  }
});

export const { removeAll, verify_email } = UserSlice.actions;

export default UserSlice.reducer;

import { Box, styled } from '@mui/material';
import ConversationProvider from 'contexts/ConversationContext';
import React from 'react';
import { useSelector } from 'react-redux';
import ChatPopupView from './ChatPopup/ChatPopupView';
const ChatPopupStackWrapper = styled(Box)`
  z-index: 999;
  position: fixed;
  bottom: 0;
  display: flex;
  right: 0;
  align-items: flex-end;
`;
export default function ChatPopupsStack() {
  const { activeConversations } = useSelector((state) => state.chat);
  return (
    <ChatPopupStackWrapper>
      {activeConversations?.map((conversation) => (
        <ConversationProvider conversation={conversation} key={conversation.id}>
          <ChatPopupView />
        </ConversationProvider>
      ))}
    </ChatPopupStackWrapper>
  );
}

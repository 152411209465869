import { doc, serverTimestamp, setDoc } from 'firebase/firestore';

import {
  FIRESTORE_USERS_COLLECTION,
  REALTIME_DATABASE_CONVERSATIONS_TABLE,
  REALTIME_DATABASE_USERS_TABLE
} from './constants';

import { firestore } from './firebase';
import { formatDate } from './_common.lib';
import moment from 'moment';

export const getServerTimeStamp = () => {
  return serverTimestamp(); //in milliseconds;
};

export const updateUserToFirebase = async ({ userId, data }) => {
  await setDoc(
    doc(firestore, FIRESTORE_USERS_COLLECTION, userId),
    {
      ...data,
      updatedAt: getServerTimeStamp()
    },
    { merge: true }
  );
};

export const getUserDatabaseReference = (userId) => {
  if (!userId) {
    return null;
  }
  return `${REALTIME_DATABASE_USERS_TABLE}/${userId}`;
};

export const getConversationReference = (conversationId) => {
  if (!conversationId) {
    return null;
  }
  return `${REALTIME_DATABASE_CONVERSATIONS_TABLE}/${conversationId}`;
};

export const getUserRealtimeObserverDatabaseReference = (userId) => {
  return `${getUserDatabaseReference(userId)}/observers`;
};

export const getUserRealtimeConnectionObserverDatabaseReference = (
  userId,
  type = ''
) => {
  return `${getUserDatabaseReference(userId)}/observers/connections${
    type !== '' ? `/${type}` : ''
  }`;
};

function groupedDays(messages) {
  return messages
    .map((doc) => ({ id: doc?.id, ...doc?.data() }))
    .reduce((acc, el) => {
      const messageDay = moment(new Date(el?.createdAt)).format('YYYY-MM-DD');
      if (acc[messageDay]) {
        return { ...acc, [messageDay]: acc[messageDay].concat([el]) };
      }
      return { ...acc, [messageDay]: [el] };
    }, {});
}

export function generateItems(messages) {
  const days = groupedDays(messages);
  const sortedDays = Object.keys(days)?.sort(
    (x, y) => moment(x, 'YYYY-MM-DD').unix() - moment(y, 'YYYY-MM-DD').unix()
  );
  const items = sortedDays?.reduce((acc, date) => {
    const sortedMessages = days[date]?.sort(
      (x, y) => new Date(x?.createdAt) - new Date(y?.createdAt)
    );
    return acc?.concat([{ type: 'day', date, id: date }, ...sortedMessages]);
  }, []);
  return items;
}

export const getShortMessageInfo = (data) => {
  let response = data?.content;
  if (data?.type === 'image') {
    response = '📷 Image';
  }
  if (data?.type === 'file') {
    response = `📄 Document: ${data?.file?.name.split('.').slice(-1)[0]}`;
  }
  if (data?.type === 'sticker') {
    response = 'A sticker';
  }
  if (data?.type === 'removed') {
    response = 'Message removed';
  }

  const seconds = data?.createdAt;
  const formattedDate = formatDate(seconds ?? Date.now());

  return response?.length > 25 - formattedDate.length
    ? `${response?.slice(0, 25 - formattedDate.length)}...`
    : response;
};

export const checkValidEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const downloadFile = (url, filename) => {
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  a.download = filename;
  document.body.append(a);
  a.click();
  a.remove();
};

export function getUrlifyText(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url) => {
    return `<a target="_blank" href="${url}">${url}</a>`;
  });
}

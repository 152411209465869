import { useEffect, useState } from 'react';

export default function useOnScreen() {
  const [isVisible, setIsVisible] = useState(false);
  const [containerRef, setContainerRef] = useState(null);

  useEffect(() => {
    let observer = undefined;
    if (typeof window !== 'undefined') {
      observer = new IntersectionObserver(([entry]) =>
        setIsVisible(entry?.isIntersecting)
      );
      if (containerRef) {
        observer?.observe(containerRef);
      }
    }
    // Remove the observer as soon as the component is unmounted
    return () => {
      if (typeof window !== 'undefined' && containerRef) {
        observer.disconnect(containerRef);
      }
    };
  }, [containerRef]);

  return [isVisible, setContainerRef, containerRef];
}
import {
  getUserDatabaseReference,
  getServerTimeStamp
} from '@/lib/functions/firebase.helpers';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo } from 'react';
import {
  getToken,
  onMessage,
  isSupported,
  getMessaging
} from 'firebase/messaging';

import {
  database,
  firebaseApp,
  firestore,
  storage
} from '@/lib/functions/firebase';
import {
  resetNotificationSlice,
  updateNotificationOnDevicePushStatusSlice,
  updateNotificationPermissionSlice,
  updateFcmTokenSlice
} from '@/reduxtoolkit/notification.slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  onDisconnect,
  onValue,
  ref,
  serverTimestamp,
  update
} from 'firebase/database';
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes
} from 'firebase/storage';
import axiosInstance from 'Axios/axiosInstance';
import { User_endpoint } from '@/api/endpoints';
import { useMutation } from 'react-query';
import { useFirestoreQueryData } from '@react-query-firebase/firestore';
import {
  collection,
  doc,
  orderBy,
  query,
  setDoc,
  where
} from 'firebase/firestore';
import {
  FIREBASE_UPLOAD_PATH,
  FIRESTORE_CONVERSATIONS_COLLECTION,
  MAXIMUM_GROUP_IMAGE_SIZE,
  THEMES
} from '@/lib/functions/constants';
import { formatFileName, formatFileSize } from '@/lib/functions/_common.lib';
import useConnectionsList, {
  ConnectionsTypeEnums
} from './connections/useConnectionsList';

export const UserStatusEnums = {
  online: {
    key: 'online',
    value: 'Online'
  },
  offline: {
    key: 'offline',
    value: 'Offline'
  },
  disconnected: {
    key: 'disconnected',
    value: 'Disconnected'
  }
};
export default function useFirebaseProvider() {
  const { currentPermission, devicePushEnabled } = useSelector(
    (state) => state.notification
  );
  const { User_details_data } = useSelector((state) => state.UserSlice);
  const currentUserId = useMemo(() => {
    return User_details_data?._id || '';
  }, [User_details_data?._id]);
  const baseId = useMemo(() => {
    return User_details_data?.userbaselogs?.baseId;
  }, [User_details_data?.userbaselogs?.baseId]);

  const dispatch = useDispatch();
  const router = useRouter();

  const updateDeviceTokenMutation = useMutation({
    mutationKey: 'update-device-token',
    mutationFn: (params) => {
      return axiosInstance.post(User_endpoint.mutations.updateFcmToken, params);
    },
    onSuccess: (data, variables) => {
      dispatch(
        updateFcmTokenSlice({
          fcmToken: variables?.deviceToken,
          status: 'auth'
        })
      );
      dispatch(updateNotificationOnDevicePushStatusSlice('on'));
    }
  });

  //get current token
  const getCurrentToken = useCallback((callback) => {
    getToken(getMessaging(firebaseApp), {
      vapidKey: process.env.NEXT_APP_PUSH_NOTIFICATION_WEB_KEY_PAIR
    })
      .then(async (currentFcmToken) => {
        if (typeof callback === 'function') {
          callback(currentFcmToken);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const storeFcmToken = useCallback((currentFcmToken) => {
    updateDeviceTokenMutation.mutate({
      deviceType: 'Web',
      deviceToken: currentFcmToken
    });
  }, []);

  //notification permission, this setup is for new token generation cause browser generates new token can be in anyday
  useEffect(() => {
    isSupported().then((support) => {
      if (support && currentUserId) {
        if (Notification.permission !== 'granted') {
          //if notification permission not granted
          if (!currentPermission) {
            //no permission set, request for permission
            Notification.requestPermission().then((permission) => {
              //permission granted, get token and update token
              if (permission === 'granted') {
                if (devicePushEnabled !== 'manuallyOff') {
                  getCurrentToken(storeFcmToken);
                }
              } else {
                //permission declined
                dispatch(
                  updateNotificationPermissionSlice(Notification.permission)
                );
                dispatch(updateNotificationOnDevicePushStatusSlice('off'));
              }
            });
          }
          //already gave permission, check for permission change
          if (
            currentPermission &&
            currentPermission !== Notification.permission
          ) {
            dispatch(
              updateNotificationPermissionSlice(Notification.permission)
            );
            dispatch(resetNotificationSlice());
          }
        } else if (devicePushEnabled !== 'manuallyOff') {
          //permission already granted
          getCurrentToken(storeFcmToken);
        }
      }
    });
  }, [currentUserId]);

  //Foreground message, background message handling is on firebase-messaging-sw.js
  useEffect(() => {
    let listenerMessage = () => {};
    isSupported().then((support) => {
      if (support && devicePushEnabled === 'on') {
        listenerMessage = onMessage(getMessaging(firebaseApp), (payload) => {
          // console.log('DEBUG: Foreground message ', payload);
          if (
            !(
              router?.pathname?.indexOf(`${payload?.data?.conversation_id}`) >
              -1
            )
          ) {
            const notification = new Notification(
              payload?.data?.title || payload?.notification?.title,
              {
                body: payload?.data?.body || payload?.notification?.body,
                icon: payload.data.icon || payload?.notification?.icon,
                data: payload.data || payload?.data,
                vibrate: [200, 100, 200],
                tag: payload?.data?.conversation_id,
                // image: payload.data.image,
                badge: payload?.data?.badge
              }
            );
            //notification click handle
            notification.onclick = (event) => {
              event.preventDefault();
              window.open(payload?.data?.click_action);
            };
          }
        });
      }
    });
    return listenerMessage;
  }, [router?.pathname, devicePushEnabled]);

  const getOfflineUpdateData = useCallback(({ status, id }) => {
    return {
      id,
      lastSeen: serverTimestamp(),
      status: status || UserStatusEnums.offline.key
    };
  }, []);

  const establishSocketConnection = useCallback(
    (userId) => {
      const refPath = getUserDatabaseReference(userId);
      if (!refPath) {
        return () => {};
      }
      const reference = ref(database, refPath);
      const connectedRef = ref(database, '.info/connected');
      return onValue(connectedRef, (snap) => {
        if (snap.val() === true) {
          update(
            reference,
            getOfflineUpdateData({
              status: UserStatusEnums.online.key,
              id: userId
            })
          );
          onDisconnect(reference).update(
            getOfflineUpdateData({
              status: UserStatusEnums.disconnected.key,
              id: userId
            })
          );
        }
      });
    },
    [getOfflineUpdateData]
  );

  //initiate user online/offline instance
  useEffect(() => {
    const connection = establishSocketConnection(currentUserId);
    return () => {
      if (typeof connection === 'function') {
        connection();
      }
    };
  }, [currentUserId]);

  //logout from firebase
  const logoutFromFirebase = useCallback(() => {
    console.log(currentUserId,'currentUserId')
    const refPath = getUserDatabaseReference(currentUserId);
    if (!refPath) {
      return null;
    }
    const refStatus = ref(database, refPath);
    return update(refStatus, getOfflineUpdateData({ id: currentUserId }));
  }, [currentUserId, getOfflineUpdateData]);

  //create observer
  const createRealtimeDatabaseObserver = useCallback((refPath, callback) => {
    if (!refPath) {
      return () => {};
    }
    return onValue(ref(database, refPath), (snap) => {
      if (typeof callback === 'function') {
        callback(snap);
      }
    });
  }, []);

  //my conversations list
  const myConversations = useFirestoreQueryData(
    ['conversations-list', currentUserId, baseId],
    currentUserId && baseId
      ? query(
          collection(firestore, FIRESTORE_CONVERSATIONS_COLLECTION),
          where('users', 'array-contains', currentUserId || ''),
          where('isGroup', 'in', [false, true]),
          where('baseId', '==', baseId),
          orderBy('updatedAt', 'desc')
        )
      : null,
    {
      subscribe: true
    },
    {
      enabled: Boolean(currentUserId) && Boolean(baseId)
    }
  );

  //upload to firebase storage
  const uploadToFirebaseStorage = useCallback(
    async (file, subDir = 'common') => {
      if (!file) {
        throw new Error('File is required');
      }
      const fileReference = storageRef(
        storage,
        `${FIREBASE_UPLOAD_PATH}/${subDir}/${formatFileName(file?.name)}`
      );
      await uploadBytes(fileReference, file);
      const downloadURL = await getDownloadURL(fileReference);
      return downloadURL;
    },
    []
  );

  //group image upload
  const groupImageUpload = useCallback(
    async (file, conversationId) => {
      if (!file) return { url: null, error: null };

      if (!file?.type?.startsWith('image')) {
        return { error: 'File is not an image' };
      }

      if (file?.size > MAXIMUM_GROUP_IMAGE_SIZE) {
        return {
          error: `Max image size is ${formatFileSize(
            MAXIMUM_GROUP_IMAGE_SIZE
          )} bytes`
        };
      }
      const url = await uploadToFirebaseStorage(
        file,
        `conversations/${conversationId}`
      );
      return { url };
    },
    [uploadToFirebaseStorage]
  );

  //create conversation root
  const createConversation = useCallback(
    async (
      currentUserId,
      users = [],
      conversationInfo = {},
      isGroup = false,
      groupData = {},
      groupImage
    ) => {
      if (!currentUserId) {
        return Promise.reject('Current user id not found.');
      }
      if (users?.length < 1) {
        return Promise.reject('Users required');
      }
      const newConversationRef = doc(
        collection(firestore, FIRESTORE_CONVERSATIONS_COLLECTION)
      );
      const conversationId = newConversationRef.id;
      if (isGroup) {
        const { url, error } = await groupImageUpload(
          groupImage,
          conversationId
        );
        if (error) {
          throw new Error(error);
        }
        groupData = {
          ...groupData,
          groupImage: url || null
        };
      }

      users = [...users, currentUserId];
      const usersSorted = users?.sort();
      let admins = users;
      if (isGroup) {
        admins = [currentUserId];
      }
      await setDoc(newConversationRef, {
        id: conversationId,
        ...conversationInfo,
        users: usersSorted,
        admins,
        isGroup,
        group: groupData,
        updatedAt: getServerTimeStamp(),
        createdAt: getServerTimeStamp(),
        theme: THEMES[0]
      });
      return Promise.resolve(newConversationRef);
    },
    [groupImageUpload]
  );

  //create group conversation
  const createGroupConversation = useCallback(
    async ({ users, groupData, groupImage }) => {
      return createConversation(
        currentUserId,
        users,
        {
          baseId
        },
        true,
        groupData,
        groupImage
      );
    },
    [currentUserId, baseId, createConversation]
  );

  //create single conversation
  const createSingleConversation = useCallback(
    async ({ users }) => {
      return createConversation(currentUserId, users, {
        baseId
      });
    },
    [currentUserId, baseId, createConversation]
  );

  /**
   *
   * Connections related functions
   *
   */

  const sentConnections = useConnectionsList({
    enabled: Boolean(currentUserId),
    type: ConnectionsTypeEnums.pending,
    extraParams: {
      baseId
    },
    currentUserId
  });

  const acceptedConnections = useConnectionsList({
    enabled: Boolean(currentUserId),
    type: ConnectionsTypeEnums.accepted,
    extraParams: {
      baseId
    },
    currentUserId
  });

  const receivedConnections = useConnectionsList({
    enabled: Boolean(currentUserId),
    type: ConnectionsTypeEnums.received,
    extraParams: {
      baseId
    },
    currentUserId
  });

  return {
    getCurrentToken,
    logoutFromFirebase,
    createRealtimeDatabaseObserver,
    currentUserId,
    myConversations,
    baseId,
    createGroupConversation,
    createSingleConversation,
    //connection related
    sentConnections,
    acceptedConnections,
    receivedConnections
  };
}

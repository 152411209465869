// import {
//   decryptData, encryptData,
//   // encryptData
// } from "./_crypto.lib";

/**
 * Check if the window object exists.
 * @returns A function that checks if the window is undefined.
 */
export function checkWindow() {
  return typeof window !== 'undefined';
}

export function checkCookie() {
  if (!checkWindow()) {
    return false;
  }
  let { cookieEnabled } = navigator;
  if (!cookieEnabled) {
    document.cookie = 'testcookie';
    cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
  }
  return cookieEnabled;
}

/**
 * It takes a key and a value, encrypts the value, and saves it in local storage
 * @param key - The key to store the data under.
 * @param value - The value to be encrypted.
 */
export function saveInLocalStorage(key, value) {
  if (checkWindow()) {
    localStorage.setItem(key, value);
  }
}

/**
 * It gets the data from the local storage, decrypts it, and returns it
 * @param key - The key to store the data in local storage.
 * @returns the decrypted data from the local storage.
 */
export function getFromLocalStorage(key) {
  if (checkWindow()) {
    const getItem = localStorage.getItem(key);

    if (getItem !== null && getItem !== undefined) {
      return getItem;
    }

    return null;
  }

  return null;
}

import { MessageProps } from '@/hooks/useConversationProvider';
import { Box, styled } from '@mui/material';
import React from 'react';
import CommonMessageItem from './CommonMessageItem';

const MessageContainer = styled(Box)`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 21px;
  display: flex;
  gap: 0.5rem;
  .message-right {
    background: #8268ff;
    color:white;
    a{
      color:white;
    }
  }
`;
export default function RightMessage({ message }) {
  return (
    <MessageContainer>
      <CommonMessageItem type="right" message={message}/>
    </MessageContainer>
  );
}

RightMessage.propTypes = MessageProps.isRequired;

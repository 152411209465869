/* eslint-disable unused-imports/no-unused-imports */
// import { Contact_us } from '@/reduxtoolkit/UserSlice';
export const baseURL = process.env.NEXT_APP_BASE_URL;
export const mediaPath = `${baseURL}/uploads`;
export const api_end_points = {
  products: 'api/products'
};
export const cms_end_point = {
  cms: '/api/cms/details',
  faq: '/api/faq/lists',
  about: 'api/aboutus/details',
  affilationCategoryList: '/api/affiliation/category/list',
  feturrdsliderlist: 'api/featureslide/details',
  homecontentdata: 'api/homecontent/details',
  seting: '/api/settings/details',
  subscription:'api/cms/subscription/details',
  subscription_plan:'api/plan/get-plan-list',
  subscription_plandetails:'api/subscription/get-user-subscription-details',
  homeslider:'api/homeslide/list',
};
// 'https://admin.militarymovesyou.com/api/';
// https://admin.militarymovesyou.com
// export const login_endpoint = {
//   Login: ''
// };
export const User_endpoint = {
  Signup: 'api/user/signup',
  Login: 'api/user/signin',
  Userdetails: 'api/user/profile',
  userupdate: 'api/user/profile/update',
  chnagepassword: 'api/user/change-password',
  forgotpassword: 'api/user/forgot-password',
  Contact_us: 'api/contact-us/add',
  featurecategory_deatils: 'api/featurecategory/details',
  aboutPolicy: 'api/aboutpolicy/details',
  affilation_cms: 'api/cms_affiliation/details',
  termsandcondition: 'api/terms-condition/details',
  forgortpassword: 'api/user/forgot-password',
  verifyOTP: 'api/user/verify-otp',
  resetPassword: 'api/user/reset-password',
  userDelete: 'api/user/delete',
  basalist: 'api/base/list',
  baselistredious: 'api/base/nearestlist',
  withoutAuthBaseNearestList: 'api/base/nearestlistWithoutAuth',
  basedetails: 'api/base/details',
  joinbase: 'api/base/change',
  memberlist: 'api/base/user/list-by-base',
  forumlist: 'api/forum/list',
  forumcreate: 'api/forumtopic/add',
  forumTopiclist: 'api/forumtopic/list',
  forumTopicdetails: 'api/forumtopic/details',
  commentpost: 'api/comment/add',
  commentlist: 'api/comment/list',
  basehistroty: 'api/user/previousbase/history',
  forumrecenttopic: 'api/forum/recent/topic/list',
  searchforumlist: 'api/forum/topic/search/list',
  topiclistbyuser: 'api/forumtopic/listbyuser',
  forundeatils: 'api/forum/details',
  friendList: 'api/friend/list',
  changeSubscription:'api/subscription/change-plan',
  autorenewalcencle:'api/subscription/cancel-auto-renewal',
  restartautorenewal:'api/subscription/restart-auto-renewal',
  restartmembershot:'api/subscription/restart-membership',
  recievedrequest:'api/friend/received-request-count',
  mutations: {
    updateFcmToken: 'api/user/device-token',
    sendFriendRequest: 'api/friend/add',
    removeSentRequest: 'api/friend/reqcancled',
    acceptFriendRequest: 'api/friend/approve',
    rejectFriendRequest: 'api/friend/rejected',
    removeFromFriend: 'api/friend/delete',
    blockFriend: 'api/friend/block',
    unblockFriend: 'api/friend/unblock',
    report: 'api/friend/report'
  },
  notifications: 'api/notification/list'
};
// export const about_policy = {
//   aboutPolicy: 'api/aboutpolicy/details'
// };

import {
  FIRESTORE_CONVERSATIONS_COLLECTION,
  FIRESTORE_MESSAGES_COLLECTION
} from '@/lib/functions/constants';
import { firestore } from '@/lib/functions/firebase';
import {
  removeConversationFromActive,
  updateConversationObserver,
  updateUserObserver
} from '@/reduxtoolkit/Chat/chat.slice';
import {
  useFirestoreDocumentData,
  useFirestoreQuery,
  useFirestoreQueryData
} from '@react-query-firebase/firestore';
import {
  collection,
  doc,
  limitToLast,
  orderBy,
  query,
  where
} from 'firebase/firestore';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useConversationObserver from './useConversationObserver';
import { useUsersInfo } from './useConversationUsersInfo';
import useFirebase from './useFirebase';
import useUserObserver from './useUserObserver';
import propTypes from 'prop-types';

export default function useConversationProvider(conversation) {
  const [conversationInitialData, updateConversationInitialData] =
    useState(conversation);
  const { observers } = useSelector((state) => state.chat);
  const { currentUserId, baseId } = useFirebase();
  const dispatch = useDispatch();

  const [minimized, setMinimized] = useState(true);
  /* Setting the minimized state to false after 100ms. For animation*/
  useEffect(() => {
    setTimeout(() => {
      setMinimized(false);
    }, 100);
  }, []);

  /* A function that takes a value and sets the minimized state to
  that value. If the value is not a boolean, it will toggle the
  minimized state. */
  const toggleMinimize = useCallback((value) => {
    setMinimized((prevMinimize) => {
      if (typeof value === 'boolean') {
        return value;
      }
      return !prevMinimize;
    });
  }, []);

  const closeConversation = useCallback(() => {
    let withoutTimeoutClose = false;
    if (minimized) {
      withoutTimeoutClose = true;
    }
    if (!withoutTimeoutClose && typeof toggleMinimize === 'function') {
      toggleMinimize(true);
      //with animation
      setTimeout(() => {
        dispatch(removeConversationFromActive(conversationInitialData.id));
      }, 350);
    } else {
      withoutTimeoutClose = true;
    }
    if (withoutTimeoutClose) {
      dispatch(removeConversationFromActive(conversationInitialData.id));
    }
  }, [conversationInitialData, minimized, toggleMinimize]);

  //initiate chat id
  const initiateConversationIdIfNotExist = useCallback(
    (snapshotData) => {
      if (snapshotData?.length > 0) {
        updateConversationInitialData((prevData) => {
          return {
            ...prevData,
            conversationId: snapshotData?.[0]?.id,
            userId: null
          };
        });
      } else {
        updateConversationInitialData(conversation);
      }
    },
    [conversation]
  );

  //check conversations with user
  const { data: conversationsWithThisUser } = useFirestoreQueryData(
    ['conversations-list', conversation?.userId, baseId],
    !conversation?.conversationId && conversation?.userId && baseId
      ? query(
          collection(firestore, FIRESTORE_CONVERSATIONS_COLLECTION),
          where('users', 'array-contains', conversation?.userId),
          where('isGroup', 'in', [false]),
          where('baseId', '==', baseId),
          orderBy('createdAt')
        )
      : null,
    {
      subscribe: true
    },
    {
      enabled:
        !conversation?.conversationId &&
        Boolean(conversation?.userId) &&
        Boolean(baseId)
    }
  );

  useEffect(() => {
    initiateConversationIdIfNotExist(conversationsWithThisUser);
  }, [conversationsWithThisUser?.length]);

  //observer for conversation
  const { data: snapConversationData } = useFirestoreDocumentData(
    [`conversations`, `${conversationInitialData?.conversationId}}`],
    currentUserId && conversationInitialData?.conversationId
      ? doc(
          firestore,
          FIRESTORE_CONVERSATIONS_COLLECTION,
          conversationInitialData?.conversationId
        )
      : null,
    {
      subscribe: true
    },
    {
      enabled:
        Boolean(currentUserId) &&
        Boolean(conversationInitialData?.conversationId)
    }
  );

  //current conversation data
  const conversationData = useMemo(() => {
    if (snapConversationData) {
      return snapConversationData;
    }
    return conversationInitialData?.conversationData;
  }, [conversationInitialData?.conversationData, snapConversationData]);

  //fetch conversation users data
  const { data: userData } = useUsersInfo({
    userIds: conversationData?.users || [],
    enabled: Boolean(conversationData?.users) && Boolean(currentUserId)
  });

  //initiate user online/offline status observer
  useUserObserver({
    enabled: !conversationData?.isGroup,
    userIds: conversationData?.users,
    callback: (value) => {
      if (value?.id) {
        dispatch(updateUserObserver(value));
      }
    }
  });

  //initiate conversation status observer
  useConversationObserver({
    enabled: Boolean(conversationInitialData?.conversationId),
    conversationId: conversationInitialData?.conversationId,
    callback: (value, conversationId) => {
      dispatch(updateConversationObserver({ value, conversationId }));
    }
  });

  //paginated messages
  // const conversationMessagesPagination = useFirestoreInfiniteQuery(
  //   ['messagesPagination', `${conversationInitialData?.conversationId}}`],
  //   currentUserId && conversationInitialData?.conversationId
  //     ? query(
  //         collection(
  //           firestore,
  //           FIRESTORE_CONVERSATIONS_COLLECTION,
  //           conversationInitialData?.conversationId,
  //           FIRESTORE_MESSAGES_COLLECTION
  //         ),
  //         orderBy('createdAt'),
  //         limit(20)
  //       )
  //     : null,
  //   (snapshot) => {
  //     const lastDocument = snapshot.docs[snapshot.docs.length - 1];
  //     if (!lastDocument) {
  //       return undefined;
  //     }
  //     // Get the next 20 documents starting after the last document fetched.
  //     return query(
  //       collection(
  //         firestore,
  //         FIRESTORE_CONVERSATIONS_COLLECTION,
  //         conversationInitialData?.conversationId,
  //         FIRESTORE_MESSAGES_COLLECTION
  //       ),
  //       orderBy('createdAt'),
  //       limit(20),
  //       startAfter(lastDocument)
  //     );
  //   },
  //   { source: 'server' },
  //   {
  //     enabled:
  //       Boolean(currentUserId) &&
  //       Boolean(conversationInitialData?.conversationId)
  //   }
  // );

  //observer messages
  const conversationMessagesObserver = useFirestoreQuery(
    ['messages', conversationInitialData?.conversationId],
    currentUserId && conversationInitialData?.conversationId
      ? query(
          collection(
            firestore,
            FIRESTORE_CONVERSATIONS_COLLECTION,
            conversationInitialData?.conversationId,
            FIRESTORE_MESSAGES_COLLECTION
          ),
          orderBy('createdAt'),
          limitToLast(20)
        )
      : null,
    {
      subscribe: true
    },
    {
      enabled:
        Boolean(currentUserId) &&
        Boolean(conversationInitialData?.conversationId)
    }
  );

  //TODO: Combine paginated and observer messages
  const conversationMessages = useMemo(() => {
    return conversationMessagesObserver.data?.docs || [];
  }, [
    conversationMessagesObserver?.data
  ]);

  const getFilteredUsers = useCallback(() => {
    const newUserData = { ...userData };
    delete newUserData[currentUserId];
    return newUserData || {};
  }, [userData, currentUserId]);

  const firstUser = useMemo(() => {
    if (conversationData?.isGroup) {
      return {};
    }
    return Object.values(getFilteredUsers())?.[0]?.data();
  }, [userData, conversationData]);

  //get current conversation user
  const currentConversationUser = useMemo(() => {
    if (conversationData?.isGroup) {
      return { user: {}, observer: {} };
    }
    return {
      user: firstUser,
      observer: observers?.users?.[firstUser?._id]
    };
  }, [conversationData, firstUser, observers?.users?.[firstUser?._id]]);

  //get current conversation name
  const conversationName = useMemo(() => {
    if (!conversationData?.isGroup) {
      return currentConversationUser?.user?.fullName || '';
    } else {
      if (!conversationData?.group?.groupName) {
        if (conversationData?.users?.length > 2) {
          return Object.values(getFilteredUsers())
            ?.map((user) => user?.data()?.first_name)
            ?.slice(0, 3)
            ?.join(', ');
        } else {
          return Object.values(userData)
            ?.map((user) => user?.data()?.first_name)
            ?.slice(0, 3)
            ?.join(', ');
        }
      }
      return conversationData?.group?.groupName;
    }
  }, [conversationData, currentConversationUser, userData]);

  //get current conversation image
  const conversationImage = useMemo(() => {
    if (!conversationData?.isGroup) {
      return [currentConversationUser];
    } else {
      if (!conversationData?.group?.groupImage) {
        if (conversationData?.users?.length > 2) {
          return Object.values(getFilteredUsers())
            ?.slice(0, 3)
            ?.map((user) => ({
              user: user?.data(),
              observer: observers?.users?.[user?.id] || {}
            }));
        } else {
          return Object.values(userData)
            ?.slice(0, 3)
            ?.map((user) => ({
              user: user?.data(),
              observer: observers?.users?.[user?.id] || {}
            }));
        }
      }
      return [
        {
          user: {
            _id: conversationData?.id,
            fullName: conversationName,
            profile_image: conversationData?.group?.groupImage
          },
          observer: {}
        }
      ];
    }
  }, [
    conversationData,
    currentConversationUser,
    userData,
    conversationName,
    observers?.users
  ]);
  
  return {
    minimized,
    toggleMinimize,
    closeConversation,
    conversationInitialData,
    conversationId: conversationInitialData?.conversationId,
    conversationUsers: userData,
    currentConversationUser,
    conversationMessages,
    conversationData,
    conversationName,
    conversationImage,
    getFilteredUsers
  };
}

export const SeverTimestamp = {
  nanoseconds:propTypes.number.isRequired,
  seconds:propTypes.number.isRequired,
};
export const MessageProps = propTypes.shape({
  content: propTypes.string.isRequired,
  type: propTypes.oneOf(['text', 'file', 'image', 'sticker', 'gif']),
  sender: propTypes.string.isRequired,
  createdAt: propTypes.shape(SeverTimestamp),
  updatedAt: propTypes.shape(SeverTimestamp)
});

export const ConversationData = propTypes.shape({
  id: propTypes.string.isRequired,
  baseId: propTypes.string,
  isGroup: propTypes.bool,
  createdAt: propTypes.shape(SeverTimestamp),
  updatedAt: propTypes.shape(SeverTimestamp),
  users: propTypes.arrayOf(propTypes.string).isRequired,
  admins: propTypes.arrayOf(propTypes.string).isRequired,
  group: propTypes.shape({
    groupImage: propTypes.string,
    groupName: propTypes.string,
    groupDescription: propTypes.string
  }),
  seen: propTypes.shape({
    [propTypes.string]: propTypes.shape({
      lastMessageId: propTypes.string.isRequired,
      seenAt: propTypes.shape(SeverTimestamp)
    })
  }),
  unreadCount: propTypes.shape({
    [propTypes.string]: propTypes.number.isRequired
  })
});
useConversationProvider.propTypes = {
  id: propTypes.string.isRequired,
  userId: propTypes.string,
  conversationId: propTypes.string,
  conversationData: ConversationData
};

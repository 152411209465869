import { Box, IconButton, styled } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';
import React, { useMemo } from 'react';
import useConversation from '@/hooks/useConversation';
import moment from 'moment';
import { UserStatusEnums } from '@/hooks/useFirebaseProvider';
import ConversationImage from './ConversationImage';

const ChatHeaderContainer = styled(Box)`
  background: #2e05f8;
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 13px;

  svg {
    color: white;
  }
`;
const ChatProfileDetails = styled(Box)`
  gap: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    font-family: Inter, sans-serif;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #bcaeff;
  }
`;
const MinimizeIconButton = styled(IconButton, {
  shouldForwardProp: (propName) => propName !== 'isMinimized'
})`
  transition: transform 0.2s ease-in-out;
  ${({ isMinimized }) => (!isMinimized ? 'transform:rotate(180deg)' : '')};
`;

export default function ChatHeader() {
  const {
    closeConversation,
    minimized,
    toggleMinimize,
    currentConversationUser,
    conversationImage,
    conversationName
  } = useConversation();

  const status = useMemo(() => {
    if (
      currentConversationUser?.observer?.status === UserStatusEnums.online.key
    ) {
      return UserStatusEnums.online.value;
    }
    if (currentConversationUser?.observer?.lastSeen) {
      return `${moment(currentConversationUser?.observer?.lastSeen).toNow(
        true
      )} ago`;
    }
    return null;
  }, [currentConversationUser?.observer?.lastSeen]);

  return (
    <ChatHeaderContainer>
      <ChatProfileDetails>
        <ConversationImage images={conversationImage} />
        <div>
          <h6>{conversationName || ''}</h6>
          <p>{status}</p>
        </div>
      </ChatProfileDetails>

      <Box>
        <MinimizeIconButton
          size="small"
          onClick={toggleMinimize}
          isMinimized={minimized}
        >
          <KeyboardArrowUpIcon fontSize="small" />
        </MinimizeIconButton>
        <IconButton size="small" onClick={closeConversation}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
    </ChatHeaderContainer>
  );
}
ChatHeader.propTypes = {};

ChatHeader.defaultProps = {};

import { configureStore } from '@reduxjs/toolkit';
// import logger from 'redux-logger';
import UserSlice from './UserSlice';
import globalSlice from './globalSlice';
import BaseSlice from './BaseSlice';
import postSlice from './postSlice';
import notificationSlice from './notification.slice';
import chatSlice from './Chat/chat.slice';
// import TestSliceSlice from './TestSlice';

const store = configureStore({
  reducer: {
    UserSlice,
    globalSlice,
    BaseSlice,
    postSlice,
    [notificationSlice.name]: notificationSlice.reducer,
    [chatSlice.name]: chatSlice.reducer
  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  devTools: true
});
export default store;

import { Box, styled } from '@mui/material';
import React from 'react';
import propTypes from 'prop-types';
const MessageTimeWrapper = styled(Box)`
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 17px;
  color: #969696;
  font-family: Inter, sans-serif;
  text-align: center;
  margin-bottom: 9px;
`;
export default function MessageTime({ time }) {
  return <MessageTimeWrapper>{time}</MessageTimeWrapper>;
}

MessageTime.propTypes = {
  time: propTypes.string.isRequired
};

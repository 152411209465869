import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentPermission: '',
  fcmToken: '',
  status: '',
  devicePushEnabled: ''
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    updateNotificationPermissionSlice: (state, { payload }) => {
      state.currentPermission = payload || 'refused';
    },

    updateNotificationOnDevicePushStatusSlice: (state, { payload }) => {
      state.devicePushEnabled = payload || '';
    },

    updateFcmTokenSlice: (state, { payload }) => {
      state.fcmToken = payload?.fcmToken;
      state.status = payload.status;
      state.currentPermission = 'granted';
    },

    resetNotificationSlice: (state) => {
      state.fcmToken = initialState?.fcmToken;
      state.status = initialState?.status;
      state.devicePushEnabled = initialState?.devicePushEnabled;
    },
    clearNotificationSlice: () => initialState
  }
});
export const {
  updateNotificationPermissionSlice,
  updateNotificationOnDevicePushStatusSlice,
  updateFcmTokenSlice,
  clearNotificationSlice,
  resetNotificationSlice
} = notificationSlice.actions;
export default notificationSlice;

import axiosInstance from 'Axios/axiosInstance';

export async function updateUserProfile({ endPoint, data, method }) {
  try {
    const config = {
      method,
      url: endPoint,
      data
    };

    const response = await axiosInstance(config);

    return response;
  } catch (error) {
    return {
      data: null,
      status: 500,
      message: error?.response?.data?.message
    };
  }
}

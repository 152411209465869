import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { toast } from 'react-toastify';
import axiosInstance from '../Axios/axiosInstance';
import { User_endpoint } from '../API/endpoints/index';

const initialState = {
  status: 'idle',
  Base_list_data: {},
  base_list_redious_data: [],
  base_list_redious_data1: [],
  base_list_redious_status: 'idle',
  locationdata: [],
  Base_deatils_data: {},
  base_join_data: {},
  base_memberlist_data: {},
  base_histroty_data: {},
  resquestCount_data:[],
};

export const Base_list = createAsyncThunk(
  User_endpoint.basalist,
  async (data) => {
    try {
      const res = await axiosInstance.post(User_endpoint.basalist, data);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const base_hisroty = createAsyncThunk(
  User_endpoint.basehistroty,
  async (data) => {
    try {
      const res = await axiosInstance.get(User_endpoint.basehistroty, data);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const resquestCount = createAsyncThunk(
  User_endpoint.recievedrequest,
  async (data) => {
    try {
      const res = await axiosInstance.get(User_endpoint.recievedrequest, data);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);








export const Base_deatils = createAsyncThunk(
  User_endpoint.basedetails,
  async (data) => {
    try {
      const res = await axiosInstance.post(User_endpoint.basedetails, data);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const base_list_redious = createAsyncThunk(
  User_endpoint.baselistredious,
  async (data) => {
    try {
      const res = await axiosInstance.post(User_endpoint.baselistredious, data);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const withOutAuthBaseListRadius = createAsyncThunk(
  User_endpoint.withoutAuthBaseNearestList,
  async (data) => {
    try {
      const res = await axiosInstance.post(
        User_endpoint.withoutAuthBaseNearestList,
        data
      );

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const withOutAuthBaseListRadius1 = createAsyncThunk(
  User_endpoint.withoutAuthBaseNearestList,
  async (data) => {
    try {
      const res = await axiosInstance.post(
        User_endpoint.withoutAuthBaseNearestList,
        data
      );

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);








export const base_join = createAsyncThunk(
  User_endpoint.joinbase,
  async (data) => {
    try {
      const res = await axiosInstance.post(User_endpoint.joinbase, data);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const base_memberlist = createAsyncThunk(
  User_endpoint.memberlist,
  async (data) => {
    try {
      const res = await axiosInstance.post(User_endpoint.memberlist, data);

      return res?.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const UserSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    removeAll: () => {
      return initialState;
    },
    verify_email: (state, action) => {
      state.verify_email_data = action?.payload;
    },

    location_data: (state, action) => {
      state.locationdata = action?.payload;
    }
  },
  extraReducers: {
    // baselist section
    [Base_list.pending]: (state) => {
      state.Base_list_status = 'loading';
    },
    [Base_list.fulfilled]: (state, { payload }) => {
      state.Base_list_status = 'idle';

      state.Base_list_data = payload?.data;
    },
    [Base_list.rejected]: (state) => {
      state.About_policy_status = 'idle';
    },

    // base_list_redious section

    [base_list_redious.pending]: (state) => {
      state.base_list_redious_status = 'loading';
    },
    [base_list_redious.fulfilled]: (state, { payload }) => {
      state.base_list_redious_status = 'idle';
      if (payload?.data?.length > 0) {
        state.base_list_redious_data = payload?.data;
      } else {
        state.base_list_redious_data = undefined;
      }
      // state.base_list_redious_data = undefined;
    },
    [base_list_redious.rejected]: (state) => {
      state.About_policy_status = 'idle';
    },
    [withOutAuthBaseListRadius.pending]: (state) => {
      state.base_list_redious_status = 'loading';
    },
    [withOutAuthBaseListRadius.fulfilled]: (state, { payload }) => {
      state.base_list_redious_status = 'idle';
      if (payload?.data?.length > 0) {
        state.base_list_redious_data = payload?.data;
      } else {
        state.base_list_redious_data = undefined;
      }
      // state.base_list_redious_data = undefined;
    },
    [withOutAuthBaseListRadius.rejected]: (state) => {
      state.About_policy_status = 'idle';
    },












    [withOutAuthBaseListRadius1.pending]: (state) => {
      state.base_list_redious_status = 'loading';
    },
    [withOutAuthBaseListRadius1.fulfilled]: (state, { payload }) => {
      state.base_list_redious_status = 'idle';
      if (payload?.data?.length > 0) {
        state.base_list_redious_data1 = payload?.data;
      } else {
        state.base_list_redious_data1 = undefined;
      }
      // state.base_list_redious_data = undefined;
    },
    [withOutAuthBaseListRadius1.rejected]: (state) => {
      state.About_policy_status = 'idle';
    },













    // base_hisroty section

    [base_hisroty.pending]: (state) => {
      state.base_list_redious_status = 'loading';
    },
    [base_hisroty.fulfilled]: (state, { payload }) => {
      state.base_histroty_status = 'idle';

      state.base_histroty_data = payload?.data;
    },
    [base_hisroty.rejected]: (state) => {
      state.About_policy_status = 'idle';
    },


    // resquestCount


    [resquestCount.pending]: (state) => {
      state.base_list_redious_status = 'loading';
    },
    [resquestCount.fulfilled]: (state, { payload }) => {
      state.resquestCount_status = 'idle';

      state.resquestCount_data = payload?.data;
    },
    [resquestCount.rejected]: (state) => {
      state.resquestCount_status = 'idle';
    },






    // Base_deatils section

    [Base_deatils.pending]: (state) => {
      state.Base_deatils_status = 'loading';
    },
    [Base_deatils.fulfilled]: (state, { payload }) => {
      state.Base_deatils_status = 'idle';
      state.Base_deatils_data = payload?.data;
    },
    [Base_deatils.rejected]: (state) => {
      state.About_policy_status = 'idle';
    },

    // base_join section

    [base_join.pending]: (state) => {
      state.base_join_status = 'loading';
    },
    [base_join.fulfilled]: (state, { payload }) => {
      state.base_join_status = 'idle';
      state.base_join_data = payload?.data;
    },
    [base_join.rejected]: (state) => {
      state.base_join_status = 'idle';
    },

    // base_memberlist section
    [base_memberlist.pending]: (state) => {
      state.base_memberlists_status = 'loading';
    },
    [base_memberlist.fulfilled]: (state, { payload }) => {
      state.base_memberlist_status = 'idle';
      state.base_memberlist_data = payload?.data;
    },
    [base_memberlist.rejected]: (state) => {
      state.base_memberlist_status = 'idle';
    }
  }
});

export const { removeAll, verify_email, location_data } = UserSlice.actions;

export default UserSlice.reducer;

import { FIRESTORE_USERS_COLLECTION } from '@/lib/functions/constants';
import { firestore } from '@/lib/functions/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

export const useUsersInfo = ({ userIds = [], enabled, maxLoad }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(!data);
  const [error, setError] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (enabled) {
      try {
        (async () => {
          //this can  block rendering
          // Promise.all(
          //   userIds.map(async (id) => {
          //     return queryClient.fetchQuery({
          //       queryFn: async () => {
          //         return getDoc(doc(firestore, FIRESTORE_USERS_COLLECTION, id));
          //       },
          //       queryKey: ['users', id],
          //       staleTime: Infinity
          //     });
          //   })
          // ).then((docs)=>{
          //   docs.forEach((doc)=>{
          //     setData((prevData) => ({
          //       ...prevData,
          //       [doc.id]: doc
          //     }));
          //   })
          // });

          //optimized performance cause it's loaded one by one, this will not take much time when loading 10+ users,
          //this will not do render blocking stuff
          let loaderInitiated = 0;
          userIds.forEach(async (id) => {
            if (maxLoad) {
              if (loaderInitiated >= maxLoad) {
                return null;
              }
              loaderInitiated += 1;
            }
            return queryClient
              .fetchQuery({
                queryFn: async () => {
                  return getDoc(doc(firestore, FIRESTORE_USERS_COLLECTION, id));
                },
                queryKey: ['users', id],
                staleTime: Infinity
              })
              .then((doc) => {
                setData((prevData) => ({
                  ...prevData,
                  [doc.id]: doc
                }));
              });
          });
          setLoading(false);
          setError(false);
        })();
      } catch (error) {
        console.error(error);
        setLoading(false);
        setError(true);
      }
    }
  }, [JSON.stringify(userIds), enabled, maxLoad]);

  return { data, loading, error };
};
